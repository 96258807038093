import { Box, Button, Grid, Toolbar, Typography } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { SaveButton, useTranslate, useDataProvider } from 'react-admin';
import { useField } from 'react-final-form';
import { constProvider } from 'providers';
import { userDictionary, UserPermissions } from '../../User.const';
import { usersFormConsts } from '../user-form/UsersForm.const';
import { UserStepProps } from '../user-form/UsersForm.models';
import { useStyles } from '../user-form/UsersForm.styles';
import { PrivilegesSection } from './privileges-section';

type UserPrivilegesProps = UserStepProps;

function useAvailablePrivileges(type: string) {
  const [availablePrivileges, setAvailablePrivileges] = useState<Set<UserPermissions>>(new Set());
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getCustomUrl(`${constProvider.RESOURCES.ROLE_PRIVILEGE.URI}?userType=${type}`)
      .then(({ data }: { data: { id: number; name: string }[] }) => new Set(data.map(({ name }) => name)))
      .then(setAvailablePrivileges)
      .catch(console.error);
  }, [dataProvider, type]);
  return availablePrivileges;
}

export function UserPrivileges(props: UserPrivilegesProps) {
  const {
    formProps,
    onCancelClick,
    onBackClick,
    hasPrev,
    hasNext,
    record: { privileges = [], type }
  } = props;
  const translate = useTranslate();
  const classes = useStyles();

  const {
    input: { onChange: privilegesChanged }
  } = useField('privileges');

  const privilegesChangedRef = useRef<((event: any) => void) | null>(null);
  privilegesChangedRef.current = privilegesChanged;
  const availablePrivileges = useAvailablePrivileges(type);

  const setPrivilege = useMemo(() => {
    let cache = privileges.filter((privilege: UserPermissions) => availablePrivileges.has(privilege));
    return ({ target: { name } }: ChangeEvent<any>, value: boolean) => {
      const set = new Set<UserPermissions>(cache);
      if (value) {
        set.add(name);
      } else {
        set.delete(name);
      }
      cache = [...set];
      if (!privilegesChangedRef.current) {
        return;
      }
      privilegesChangedRef.current({ target: { value: cache } });
    };
  }, [privileges, availablePrivileges]);

  return (
    <Box className={classes.container}>
      <div className={classes.privilegesSection}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {translate(usersFormConsts.SECTIONS_SUBTITLE)}
            </Typography>
          </Grid>
          <Grid item xs={2} />
          {userDictionary.PRIVILEGES.filter(
            (privilege) => !privilege.name.endsWith(constProvider.PRIVILEGES.READ_ONLY_PROPERTIES)
          ).map(({ name }) => (
            <Grid item xs={2} key={name}>
              <Typography variant="subtitle2" className={classes.privilege}>
                {translate(name)}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <PrivilegesSection
          availablePrivileges={availablePrivileges}
          setPrivilege={setPrivilege}
          privileges={privileges}
          section={userDictionary.USER_PRIVILEGES.SECTIONS}
        />
      </div>
      <div className={classes.privilegesSection}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {translate(usersFormConsts.DASHBOARDS_SUBTITLE)}
            </Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <Typography variant="subtitle2" className={classes.privilege}>
              {translate(userDictionary.PRIVILEGES[1].name)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" className={classes.privilege}>
              {translate(userDictionary.PRIVILEGES[3].name)}
            </Typography>
          </Grid>
        </Grid>
        <PrivilegesSection
          availablePrivileges={availablePrivileges}
          setPrivilege={setPrivilege}
          privileges={privileges}
          section={userDictionary.USER_PRIVILEGES.DASHBOARDS}
        />
      </div>
      <Toolbar className={classes.toolbar}>
        <Box>
          {hasPrev && (
            <Button color="primary" className={classes.cancelButton} onClick={onBackClick}>
              {translate(usersFormConsts.BUTTONS.backButtonCaption)}
            </Button>
          )}
        </Box>
        <Box>
          <Button color="primary" className={classes.cancelButton} onClick={onCancelClick}>
            {translate(usersFormConsts.BUTTONS.cancelButtonCaption)}
          </Button>
          {hasNext ? (
            <Button color="primary" onClick={formProps.handleSubmit}>
              {translate(usersFormConsts.BUTTONS.nextButtonCaption)}
            </Button>
          ) : (
            <SaveButton saving={formProps.submitting} handleSubmitWithRedirect={formProps.handleSubmit} />
          )}
        </Box>
      </Toolbar>
    </Box>
  );
}
