import React from 'react';
import { TextInput, SelectInput, Filter, ReferenceInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { constProvider } from 'providers';

const useStyles = makeStyles({
  input: {
    minWidth: '220px',
    marginBottom: '20px'
  }
});

export function DeviceFilters(props: any) {
  const classes = useStyles();

  return (
    <Filter {...props}>
      <TextInput className={classes.input} source="serialNumber" alwaysOn />

      {/*<ReferenceInput*/}
      {/*  className={classes.input}*/}
      {/*  source="deviceType"*/}
      {/*  reference={constProvider.RESOURCES.DEVICE_TYPES.URI}*/}
      {/*  alwaysOn*/}
      {/*>*/}
      {/*  <SelectInput />*/}
      {/*</ReferenceInput>*/}

      <ReferenceInput
        className={classes.input}
        source="medicalCenterId"
        reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
        alwaysOn
      >
        <SelectInput />
      </ReferenceInput>
    </Filter>
  );
}
