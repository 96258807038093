import { IconButton } from '@material-ui/core';
import UnlinkIcon from '@material-ui/icons/LinkOff';
import SyncIcon from '@material-ui/icons/Sync';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useRefresh } from 'react-admin';
import { constProvider } from 'providers';

export function useUnlinkButton<T extends { id: number }>(
  resource: keyof typeof constProvider.RESOURCES,
  data: object
) {
  const refresh = useRefresh();
  const [pending, setPending] = useState<number | null>(null);
  const [unlink, { loading, loaded }] = useMutation({
    type: 'delete',
    resource: constProvider.RESOURCES[resource].URI
  });
  useEffect(() => {
    if (!loaded) return;
    refresh();
  }, [refresh, loading, loaded]);
  return useCallback(
    function UnlinkButton(item: T) {
      const unlinkBound = useCallback(
        (e: React.MouseEvent<any>) => {
          e.preventDefault();
          e.stopPropagation();

          setPending(item.id);
          unlink({
            payload: { id: item.id, previousData: data }
          });
        },
        [item]
      );
      return (
        <IconButton disabled={loading} onClick={loading ? undefined : unlinkBound}>
          {loading && pending === item.id ? <SyncIcon /> : <UnlinkIcon />}
        </IconButton>
      );
    },
    [unlink, loading, data, pending]
  );
}
