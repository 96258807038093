import React, { useCallback, useMemo, useState } from 'react';
import { Datagrid, DateField, FunctionField, ReferenceField, TextField, useTranslate } from 'react-admin';

import { FiltersButton, List, PageContentWithTitle } from 'components/common';
import { constProvider } from 'providers';

import { DeviceField } from '../device-field';
import { measurementConsts } from '../measurement.const';
import { MeasurementStatus } from '../measurement-status';
import { MeasurementTypeField } from '../measurement-type';
import { MeasurementFilters } from './measurement-filters/measurement-filters';
import moment from 'moment';

export function MeasurementList(props: any) {
  const translate = useTranslate();
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = useCallback(() => setShowFilters(!showFilters), [showFilters]);

  const filterDefaultValues = useMemo(() => {
    return {
      examDateFrom: moment().subtract(1, 'month').toDate()
    };
  }, []);

  const onRowClicked = useCallback(function (id: string, basePath: string, record: any) {
    return `/measurement/${record.patientId}/${record.measurementType}`;
  }, []);

  return (
    <PageContentWithTitle title={measurementConsts.LIST_TITLE}>
      <List
        {...props}
        filters={showFilters ? <MeasurementFilters /> : undefined}
        title={translate(measurementConsts.LIST_NAME)}
        bulkActionButtons={false}
        exporter={false}
        actions={<FiltersButton toggleFilters={toggleFilters} />}
        filterDefaultValues={filterDefaultValues}
      >
        <Datagrid rowClick={onRowClicked}>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="pesel" />
          <DateField source="examDate" showTime={true} />
          <DateField source="creationDate" showTime={true} />
          <MeasurementTypeField source="measurementType" />
          <FunctionField source="status" render={MeasurementStatus} />
          <DeviceField source="deviceId" />
          <ReferenceField
            source="medicalCenterId"
            reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
            sortBy="medicalCenterName"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      </List>
    </PageContentWithTitle>
  );
}
