import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { PatientDetailsMeasurementDto } from 'models/models';
import React from 'react';
import { useTranslate } from 'react-admin';
import { patientAddressConsts } from './patient-address-card.const';
import { useStyles } from './patient-address-card.styles';

type PatientAddressCard = {
  className?: string;
  data: PatientDetailsMeasurementDto;
  hideTitle?: boolean;
};

export function PatientAddressCard({ className, data }: PatientAddressCard) {
  const translate = useTranslate();
  const classes = useStyles();

  const { address, city, postCode, phoneNumber, email } = data;

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className={classes.header}>
              {translate(patientAddressConsts.LABEL)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientAddressConsts.ADDRESS)}:</span>
              <span className={classes.text}>{address}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientAddressConsts.CITY)}:</span>
              <span className={classes.text}>{city}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientAddressConsts.POST_CODE)}:</span>
              <span className={classes.text}>{postCode}</span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientAddressConsts.PHONE_NUMBER)}:</span>
              <span className={classes.text}>{phoneNumber}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientAddressConsts.EMAIL)}:</span>
              <span className={classes.text}>{email}</span>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
