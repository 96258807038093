import React, { useCallback, useEffect, useState } from 'react';
import { EditView } from 'types';
import { Device } from 'models/models';
import {
  useDataProvider,
  useTranslate,
  useMutation,
  Loading,
  SelectInput,
  SaveButton,
  required,
  DateInput,
  useNotify
} from 'react-admin';
import { constProvider } from 'providers';
import { Grid, makeStyles, Box, Toolbar, Typography } from '@material-ui/core';
import { deviceConsts } from '../../Device.const';
import { get, matches, cond, constant, range } from 'lodash';
import { Form, FormRenderProps, Field } from 'react-final-form';
import moment from 'moment';
import { fontWeight } from 'style';
import { ConditionField } from 'components/common';
import { DeviceCommandStatus } from './device-command-status';

type DeviceCommandsProps = EditView<Device>;
type Command = string;
type CommandMessage = { id: string; name: string };

const PLAY_MESSAGE_COMMAND = 'PLAY_MESSAGE';
const SEND_DEBUG_COMMAND = 'SEND_DEBUG';

interface CommandFormValues {
  command: string;
  date?: string;
  messageId?: string;
}

export function DeviceCommands({ record }: DeviceCommandsProps) {
  const deviceId = record.id;
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [commands, setCommands] = useState<null | Array<{ id: Command; name: string }>>(null);
  const [commandMessages, setCommandMessages] = useState<null | CommandMessage[]>(null);
  const classes = useStyles();
  const notify = useNotify();

  const [mutate] = useMutation();

  useEffect(() => {
    dataProvider.getCustomUrl(constProvider.RESOURCES.DEVICE_OPERATION.URI).then(({ data }: { data: Command[] }) => {
      setCommands(
        data.map((command) => ({
          id: command,
          name: get(deviceConsts.COMMANDS, command, command)
        }))
      );
    });

    // TODO: fetch from backend
    setCommandMessages(range(0, 50).map((messageId) => ({ id: messageId.toString(), name: `Wiadomość ${messageId}` })));
  }, [dataProvider]);

  const handleSubmit = useCallback(
    ({ command, date, messageId }: CommandFormValues) => {
      const payload = cond([
        [matches(PLAY_MESSAGE_COMMAND), constant({ details: { messageNumber: Number(messageId) } })],
        [matches(SEND_DEBUG_COMMAND), constant({ details: { debugFileDateEpoch: moment(date).unix() } })],
        [() => true, constant({})]
      ])(command);

      const operationToSend = {
        deviceId,
        operation: command,
        ...payload
      };

      return Promise.resolve().then(() =>
        mutate(
          {
            type: 'create',
            resource: constProvider.RESOURCES.DEVICE_OPERATION.URI,
            payload: { id: deviceId, data: operationToSend }
          },
          {
            onSuccess: () => {
              notify(deviceConsts.COMMANDS_NOTIFICATIONS.SUCCESS, 'success', {
                smart_count: 1
              });
            },
            onFailure: () => {
              notify(deviceConsts.COMMANDS_NOTIFICATIONS.FAILURE, 'failure', {
                smart_count: 1
              });
            }
          }
        )
      );
    },
    [deviceId, mutate, notify]
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<CommandFormValues>) => {
      return (
        <form>
          <Box className={classes.container}>
            <Typography variant="subtitle1" className={classes.sectionHeader}>
              {translate(deviceConsts.COMMANDS_LABELS.TITLE)}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  label={deviceConsts.COMMANDS_LABELS.COMMAND}
                  name="command"
                  validate={required()}
                  component={SelectInput}
                  source="command"
                  choices={commands}
                />
              </Grid>
            </Grid>

            <ConditionField fieldValue="command" shouldEqual={SEND_DEBUG_COMMAND}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Field
                    fullWidth
                    name="date"
                    label={deviceConsts.COMMANDS_LABELS.DATE}
                    validate={required()}
                    component={DateInput}
                    source="date"
                  />
                </Grid>
              </Grid>
            </ConditionField>

            <ConditionField fieldValue="command" shouldEqual={PLAY_MESSAGE_COMMAND}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    name="messageId"
                    validate={required()}
                    component={SelectInput}
                    choices={commandMessages}
                    label={deviceConsts.COMMANDS_LABELS.MESSAGE_ID}
                    source="messageId"
                  />
                </Grid>
              </Grid>
            </ConditionField>
          </Box>

          <DeviceCommandStatus deviceId={deviceId} command={formProps.form.getState().values.command} />

          <Toolbar>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <SaveButton
                label={deviceConsts.COMMANDS_LABELS.SEND_BUTTON}
                saving={formProps.submitting}
                handleSubmitWithRedirect={formProps.handleSubmit}
              />
            </Box>
          </Toolbar>
        </form>
      );
    },
    [deviceId, commands, commandMessages, classes, translate]
  );

  if (null === commands || null == commandMessages) {
    return <Loading />;
  }

  return (
    <Form onSubmit={handleSubmit} subscription={defaultSubscription} keepDirtyOnReinitialize render={renderForm} />
  );
}

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: ' 14px 30px 30px'
  },
  select: {
    width: '100%'
  },
  sectionHeader: {
    color: theme.palette.text.secondary,
    fontWeight: fontWeight.medium
  }
}));

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
};
