import * as Types from 'AppTypes';
import { constProvider } from '../../providers';

export const getDevice = (state: Types.RootState, deviceId: string) =>
  // @ts-ignore
  state.admin.resources[constProvider.RESOURCES.DEVICE.URI].data[deviceId];

export const getMeasuringDevice = (state: Types.RootState, measuringDeviceId: string) =>
  // @ts-ignore
  state.admin.resources[constProvider.RESOURCES.MEASURING_DEVICE.URI].data[measuringDeviceId];

export const getPatient = (state: Types.RootState, patientId: string) =>
  // @ts-ignore
  state.admin.resources[constProvider.RESOURCES.PATIENT.URI].data[patientId];

export const getPatientLocalization = (state: Types.RootState, patientId: string) =>
  // @ts-ignore
  state.admin.resources[constProvider.RESOURCES.PATIENT_LOCATION.URI].data[patientId];

export const getPathname = (state: Types.RootState) =>
  // @ts-ignore
  state.router.location.pathname;
