import { makeStyles } from '@material-ui/core/styles';
import { fontWeight } from 'style';
import { colors } from 'style/colors';

export const useStyles = makeStyles((theme) => ({
  content: {
    padding: '16px 18px',
    '&:last-child': {
      paddingBottom: '10px'
    }
  },
  header: {
    marginBottom: '12px',
    fontWeight: fontWeight.medium
  },
  prop: {
    color: theme.palette.text.primary,
    fontWeight: fontWeight.medium,
    marginRight: '5px'
  },
  text: {
    color: theme.palette.text.secondary
  },
  label: {
    color: 'white',
    width: '112px',
    height: '23px',
    borderRadius: '3px',
    fontSize: '14px',
    fontWeight: fontWeight.medium
  },
  red: {
    backgroundColor: colors.darkRed
  },
  blue: {
    backgroundColor: colors.darkerBlue
  },
  green: { backgroundColor: colors.lighterGreen },
  yellow: { backgroundColor: colors.darkYellow },
  unknown: { backgroundColor: colors.gray }
}));
