import { makeStyles } from '@material-ui/core/styles';
import * as styles from 'style';

export const useStyles = makeStyles({
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    position: 'relative',
    paddingLeft: '10px'
  },
  namesContainer: {
    textAlign: 'right'
  },
  name: {
    fontSize: '14px',
    margin: '0'
  },
  circle: {
    color: styles.colors.primaryBlue,
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginLeft: '10px',
    backgroundColor: styles.colors.green,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  initials: {
    color: 'white',
    width: '100%',
    textAlign: 'center'
  },
  originalUserMenu: {
    position: 'absolute',
    width: '100%'
  }
});
