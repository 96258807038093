export const deviceConsts = Object.freeze({
  LIST_TITLE: 'resources.device.name',
  LIST_HEADER: 'resources.device.list.header',
  EDIT_TITLE: 'resources.device.name',
  EDIT_SUBTITLE: 'resources.device.edit.subtitle',
  EDIT_HEADER: 'resources.device.edit.header',
  TABS: {
    PARAMETERS_TAB: 'resources.device.edit.tabs.parameters',
    HISTORY_TAB: 'resources.device.edit.tabs.history',
    MEASURING_DEVICES_TAB: 'resources.device.edit.tabs.measuringDevice',
    PROGRAMMING_TAB: 'resources.device.edit.tabs.programming',
    COMMANDS_TAB: 'resources.device.edit.tabs.commands'
  },
  FIELDS: {
    SERIAL_NUMBER: 'resources.device.edit.fields.serialNumber',
    AVAILABLE: 'resources.device.edit.fields.available',
    TYPE: 'resources.device.fields.deviceType'
  },
  AVAILABILITY: {
    AVAILABLE: 'common.deviceAvailability.available',
    NOT_AVAILABLE: 'common.deviceAvailability.notAvailable'
  },
  VALIDATION: {
    PHONE_NUMBER: 'validation.phoneNumberInvalidMessage'
  },
  BUTTONS: {
    CANCEL_BUTTON: 'common.button.cancel'
  },
  MEASURING_DEVICE: {
    LABELS: {
      TITLE: 'resources.device.measuringDevice.title',
      ASSIGN_TITLE: 'resources.device.measuringDevice.assignTitle',
      DEVICE_CHOICE_SUBTITLE: 'resources.device.measuringDevice.deviceChoiceSubtitle',
      ACTION: 'resources.device.measuringDevice.labels.action',
      UNLINK: 'resources.device.measuringDevice.labels.unlink',
      SERIAL_NUMBER: 'resources.device.measuringDevice.labels.serialNumber',
      TYPE: 'resources.device.measuringDevice.labels.type',
      SIM_NUMBER: 'resources.device.measuringDevice.labels.simNumber'
    }
  },
  COMMANDS: {
    NOT_SELECTED: 'resources.device.commands.NOT_SELECTED',
    UPDATE_FIRMWARE: 'resources.device.commands.UPDATE_FIRMWARE',
    RESTART: 'resources.device.commands.RESTART',
    RESTORE_TO_FACTORY_SETTINGS: 'resources.device.commands.RESTORE_TO_FACTORY_SETTINGS',
    ERASE_MEMORY: 'resources.device.commands.ERASE_MEMORY',
    SEND_STATUS: 'resources.device.commands.SEND_STATUS',
    PLAY_MESSAGE: 'resources.device.commands.PLAY_MESSAGE',
    SEND_DEBUG: 'resources.device.commands.SEND_DEBUG'
  },
  COMMANDS_LABELS: {
    TITLE: 'resources.device.commands.labels.main',
    COMMAND: 'resources.device.commands.labels.command',
    DATE: 'resources.device.commands.labels.date',
    MESSAGE_ID: 'resources.device.commands.labels.messageId',
    SEND_BUTTON: 'resources.device.commands.labels.sendButton'
  },
  COMMANDS_NOTIFICATIONS: {
    SUCCESS: 'resources.device.commands.notifications.success',
    FAILURE: 'resources.device.commands.notifications.failure'
  },
  PROGRAMMING: {
    RANGE_HELPER: 'resources.device.programming.helpers.range'
  },
  PROGRAMMING_NOTIFICATIONS: {
    SUCCESS: 'resources.device.programming.notifications.success',
    FAILURE: 'resources.device.programming.notifications.failure'
  }
});
