import React, { useEffect, useState } from 'react';
import { LinearProgress, useDataProvider, useTranslate } from 'react-admin';
import { constProvider } from '../../../../../../providers';
import classNames from 'classnames';
import { makeStyles, Box } from '@material-ui/core';

interface DeviceCommandStatusProps {
  deviceId: string | number;
  command: string;
  className?: string;
}

type CommandStatus = { date: string; status: string };

export function DeviceCommandStatus({ className, deviceId, command }: DeviceCommandStatusProps) {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();
  const [lastDeviceOperation, setLastDeviceOperation] = useState<null | undefined | CommandStatus>(null);

  useEffect(() => {
    if (command !== undefined) {
      setLastDeviceOperation(undefined);
      dataProvider
        .getCustomUrl(`deviceOperation/${deviceId}/operationStatus/${command}`)
        .then(({ data }: { data: null | CommandStatus }) => {
          setLastDeviceOperation(data);
        });
    }
  }, [dataProvider, deviceId, command]);

  if (lastDeviceOperation === undefined) {
    return (
      <Box className={classes.container}>
        <LinearProgress />
      </Box>
    );
  }

  if (command === undefined) {
    return null;
  }

  return (
    <Box className={classNames([className, classes.container])}>
      <ul className={classes.list}>
        <li className={classes.listElement}>
          {translate(`resources.${constProvider.RESOURCES.DEVICE.URI}.commands.statusLabel.date`)}{' '}
          {lastDeviceOperation ? lastDeviceOperation.date : '-'}
        </li>
        <li className={classes.listElement}>
          {translate(`resources.${constProvider.RESOURCES.DEVICE.URI}.commands.statusLabel.command`)} {command}
        </li>
        <li className={classes.listElement}>
          {translate(`resources.${constProvider.RESOURCES.DEVICE.URI}.commands.statusLabel.status`)}{' '}
          {lastDeviceOperation ? lastDeviceOperation.status : '-'}
        </li>
      </ul>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: ' 14px 30px 30px'
  },
  list: { listStyle: 'none', padding: 0 },
  listElement: {
    listStyle: 'none'
  }
}));
