import React, { useEffect, useState } from 'react';
import { MeasurementDetailTypeEnum, MeasurementType, TileStat } from '../index';
import { LinearProgress, useDataProvider } from 'react-admin';
import { MeasurementCard } from './measurement-card';
import { measurementDetailConsts } from '../measurement-detail.const';

interface MeasurementTilesProps {
  readonly patientId: string;
  readonly measurementType: MeasurementType;
}

export function MeasurementTiles({ measurementType, patientId }: MeasurementTilesProps) {
  const dataProvider = useDataProvider();
  const [tileStats, setTileStats] = useState<undefined | null | Map<TileStat['statisticType'], TileStat>>(undefined);

  useEffect(() => {
    dataProvider
      .getCustomUrl(`patient/${patientId}/measurement/stats/${measurementType}`)
      .then(({ data }: { data: Array<TileStat> }) => {
        setTileStats(
          data.reduce((map, tileData) => {
            map.set(tileData.statisticType, tileData);
            return map;
          }, new Map())
        );
      })
      .catch(() => setTileStats(null));
  }, [dataProvider, patientId, measurementType]);

  if (null === tileStats) {
    return null;
  }

  if (undefined === tileStats) {
    return <LinearProgress />;
  }

  return (
    <>
      <MeasurementCard
        label={measurementDetailConsts.TILES.MID}
        measurementType={measurementType as MeasurementDetailTypeEnum}
        {...tileStats.get('AVERAGE')!}
      />
      <MeasurementCard
        label={measurementDetailConsts.TILES.MIN}
        measurementType={measurementType as MeasurementDetailTypeEnum}
        {...tileStats.get('MINIMUM')!}
      />
      <MeasurementCard
        label={measurementDetailConsts.TILES.MAX}
        measurementType={measurementType as MeasurementDetailTypeEnum}
        {...tileStats.get('MAXIMUM')!}
      />
    </>
  );
}
