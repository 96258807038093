import { HttpError } from 'ra-core';
import { reduce, get } from 'lodash';
import { constProvider } from '../../providers';

export const parseFormErrors = (error: HttpError, translate: (message: string) => string) => {
  const response = error.body;

  if (!response) {
    return {};
  }

  const errors = response.errors;

  if (!errors) {
    return {};
  }

  return reduce(
    errors,
    (acc: any, e: { fieldName: string; errorDescription: string }) => ({
      ...acc,
      [e.fieldName]: translate(
        get(constProvider.FORM_ERROR_TO_MESSAGE, e.errorDescription, constProvider.DEFAULT_ERROR_MESSAGE)
      )
    }),
    {}
  );
};
