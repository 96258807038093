export const patientCardSectionConsts = Object.freeze({
  INTERVIEW_HISTORY: 'resources.patient.edit.card.interviewHistory',
  EXAMINATION_HISTORY: 'resources.patient.edit.card.examinationHistory',
  MEDICATION_HISTORY: 'resources.patient.edit.card.medicationHistory',
  PHYSICAL_HISTORY: 'resources.patient.edit.card.physicalHistory',
  DIAGNOSIS_HISTORY: 'resources.patient.edit.card.diagnosisHistory',

  INTERVIEW: 'resources.patient.edit.card.interview',
  EXAMINATION: 'resources.patient.edit.card.examination',
  MEDICATION: 'resources.patient.edit.card.medication',
  PHYSICAL: 'resources.patient.edit.card.physical',
  DIAGNOSIS: 'resources.patient.edit.card.diagnosis'
});
