import { makeStyles } from '@material-ui/core/styles';
import { fontWeight } from 'style';

export const useStyles = makeStyles(() => ({
  content: {
    padding: '16px 18px',
    '&:last-child': {
      paddingBottom: '10px'
    }
  },
  label: {
    marginBottom: '30px',
    fontWeight: fontWeight.medium
  },
  valueRed: {
    color: '#d0021b',
    fontWeight: fontWeight.medium
  },
  valueYellow: {
    color: '#fd8d00',
    fontWeight: fontWeight.medium
  },
  rowRed: {
    backgroundColor: 'rgba(255, 241, 241, 0.7)'
  },
  rowYellow: {
    backgroundColor: 'rgba(255, 249, 232, 0.7)'
  },
  clickable: {
    cursor: 'pointer'
  }
}));
