import React, { useMemo } from 'react';
import { DateInput, Filter, SelectInput, TextInput, ReferenceInput } from 'react-admin';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { constProvider } from 'providers';
import { measurementDictionary } from '../../measurement.const';

const useStyles = makeStyles({
  input: {
    minWidth: '220px'
  },
  filters: {
    'margin-bottom': '20px'
  }
});

type MeasurementFiltersType = { className?: string } & any;

export function MeasurementFilters({ className, ...props }: MeasurementFiltersType) {
  const classes = useStyles();

  const filterClassName = useMemo(() => classnames(classes.filters, className), [className, classes.filters]);

  return (
    <Filter {...props} className={filterClassName}>
      <DateInput className={classes.input} source="examDateFrom" alwaysOn />
      <DateInput className={classes.input} source="examDateTo" alwaysOn />
      <ReferenceInput
        className={classes.input}
        source="medicalCenterId"
        reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
        alwaysOn
      >
        <SelectInput variant="standard" />
      </ReferenceInput>
      <TextInput className={classes.input} source="deviceSerialNumber" alwaysOn />
      <TextInput className={classes.input} source="firstName" alwaysOn />
      <TextInput className={classes.input} source="lastName" alwaysOn />
      <TextInput className={classes.input} source="pesel" alwaysOn />
      <SelectInput className={classes.input} source="measurementType" alwaysOn choices={measurementDictionary.TYPES} />
      <SelectInput className={classes.input} source="status" alwaysOn choices={measurementDictionary.STATUSES} />
    </Filter>
  );
}
