import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { PatientCategoryBadge } from '../../components/common/patient-category-badge/patient-category-badge';
import { PatientCategoryEnum, PatientStatusDto } from '../../models/models';
import { constProvider } from '../../providers';

export function usePatientCategoryBadge(patientId: number): { PatientCategoryBadge: FC } {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [category, setCategory] = useState<PatientCategoryEnum | string>('-');
  useEffect(() => {
    if (patientId) {
      dataProvider
        .getCustomUrl(`${constProvider.RESOURCES.PATIENT.URI}/${patientId}/measurement/status`)
        .then(({ data }: { data: PatientStatusDto }) => {
          setCategory(data.patientCategory);
        })
        .catch((error: Error) => {
          notify(error.message, 'warning');
        });
    }
  }, [dataProvider, patientId, notify]);

  return useMemo(
    () => ({
      PatientCategoryBadge() {
        return <PatientCategoryBadge component="span" category={category} />;
      }
    }),
    [category]
  );
}
