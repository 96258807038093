import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryWithStore, useTranslate, LinearProgress, useDataProvider } from 'react-admin';
import { PatientDetailsMeasurementDto } from 'models/models';
import { constProvider } from 'providers';

import { basicInfoConsts } from './basic-info-card.const';
import { useStyles } from './basic-info-card.styles';
import { usePatientCategoryBadge } from '../../../../hooks/common';

interface BasicInfoCard {
  readonly patientId: string;
  readonly className?: string;
}

export function BasicInfoCard({ patientId }: BasicInfoCard) {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();

  const [medicalcenterName, setMedicalcenterName] = useState<null | undefined | string>(undefined);
  const [deviceSerialNumber, setDeviceSerialNumber] = useState<null | undefined | string>(undefined);

  const { loading: patientLoading, error, data: patientDetails } = useQueryWithStore({
    type: 'getOne',
    resource: constProvider.RESOURCES.PATIENT.URI,
    payload: { id: patientId }
  });

  const { PatientCategoryBadge } = usePatientCategoryBadge(Number.parseInt(patientId, 10));

  useEffect(() => {
    if (patientDetails) {
      if (patientDetails.medicalCenterId) {
        dataProvider
          .getOne(constProvider.RESOURCES.MEDICAL_CENTER.URI, { id: patientDetails.medicalCenterId })
          .then(({ data: { name } }: { data: { name: string } }) => {
            setMedicalcenterName(name);
          });
      } else {
        setMedicalcenterName(null);
      }

      if (patientDetails.deviceId) {
        dataProvider
          .getOne(constProvider.RESOURCES.DEVICE.URI, { id: patientDetails.deviceId })
          .then(({ data: { serialNumber } }: { data: { serialNumber: string } }) => {
            setDeviceSerialNumber(serialNumber);
          });
      } else {
        setDeviceSerialNumber(null);
      }
    }
  }, [dataProvider, patientDetails]);

  if (patientLoading) {
    return (
      <Grid item component={Card} xs className={classes.container}>
        <CardContent className={classes.content}>
          <LinearProgress />
        </CardContent>
      </Grid>
    );
  }

  if (error) {
    return null;
  }

  const { firstName, lastName, gender, age, pesel, deviceId } = patientDetails as PatientDetailsMeasurementDto;

  return (
    <Grid item component={Card} xs className={classes.container}>
      <CardContent className={classes.content}>
        <Typography className={classes.prop} gutterBottom>
          <span>{translate(basicInfoConsts.NAME)}:</span>
          <span className={classes.text}>{firstName}</span>
        </Typography>
        <Typography className={classes.prop} gutterBottom>
          <span>{translate(basicInfoConsts.SURNAME)}:</span>
          <span className={classes.text}>{lastName}</span>
        </Typography>
        <Typography className={classes.prop} gutterBottom>
          <span>{translate(basicInfoConsts.GENDER)}:</span>
          <span className={classes.text}>{translate(get(constProvider.GENDER_TO_LABEL, gender))}</span>
        </Typography>
        <Typography className={classes.prop} gutterBottom>
          <span>{translate(basicInfoConsts.AGE)}:</span>
          <span className={classes.text}>{age}</span>
        </Typography>
        <Typography className={classes.prop} gutterBottom>
          <span>{translate(basicInfoConsts.IDENTIFIER)}:</span>
          <span className={classes.text}>{pesel}</span>
        </Typography>
        {undefined === medicalcenterName ? (
          <LinearProgress />
        ) : (
          <Typography className={classes.prop} gutterBottom>
            <span>{translate(basicInfoConsts.MEDICAL_CENTER_NAME)}:</span>
            <span className={classes.text}>{medicalcenterName === null ? '-' : medicalcenterName}</span>
          </Typography>
        )}

        {undefined === deviceSerialNumber ? (
          <LinearProgress />
        ) : (
          <Typography className={classes.prop} gutterBottom>
            <span>{translate(basicInfoConsts.DEVICE)}:</span>
            {deviceId === null ? (
              <span>-</span>
            ) : (
              <a href={`${constProvider.RESOURCES.DEVICE.URI}/${deviceId}`} className={classes.text}>
                {deviceSerialNumber}
              </a>
            )}
          </Typography>
        )}

        <Typography className={classes.prop} gutterBottom>
          <span>{translate(basicInfoConsts.CATEGORY)}:</span>
          <span className={classes.badge}>
            <PatientCategoryBadge />
          </span>
        </Typography>
      </CardContent>
    </Grid>
  );
}
