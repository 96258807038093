import React, { useState } from 'react';
import { Datagrid, TextField, useTranslate, ReferenceField } from 'react-admin';
import { constProvider } from 'providers';
import { List, PageContentWithTitle, CreateInstanceAside, BooleanField } from 'components/common';
import { usePermissionsMap } from 'hooks/common';
import { FiltersButton } from 'components/common/FiltersButton/FiltersButton';
import { patientConsts } from '../Patient.const';
import { PatientFilters } from './patient-filters/PatientFilters';

export function PatientList(props: any) {
  const translate = useTranslate();
  const [showFilters, setShowFilters] = useState(false);
  const permissions = usePermissionsMap();

  return (
    <PageContentWithTitle title={patientConsts.LIST_TITLE}>
      <List
        {...props}
        filters={showFilters ? <PatientFilters /> : undefined}
        title={translate(patientConsts.PAGE_TITLE)}
        bulkActionButtons={false}
        exporter={false}
        actions={<FiltersButton toggleFilters={() => setShowFilters(!showFilters)} />}
        aside={permissions.any('SECTION_PATIENTS_ADD', <CreateInstanceAside />)}
      >
        <Datagrid rowClick="edit">
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="pesel" />
          <ReferenceField
            source="medicalCenterId"
            reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
            sortBy="medicalCenterName"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="active" />
        </Datagrid>
      </List>
    </PageContentWithTitle>
  );
}
