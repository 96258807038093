export const medicalCenterFormConsts = Object.freeze({
  SUPERIOR_MEDICALCENTER_NAME_SUBTITLE: 'medicalcenter.form.details.superiorMedicalcenterNameLabel',
  ADMIN_SUBTITLE: 'medicalcenter.form.admin.subtitle',
  SMS_NOTIFICATION_SUBTITLE: 'medicalcenter.form.smsNotifications.subtitle',
  LABELS: {
    NAME: 'medicalcenter.form.details.nameLabel',
    NIP: 'medicalcenter.form.details.nipLabel',
    ADDRESS: 'medicalcenter.form.details.addressLabel',
    POSTAL_CODE: 'medicalcenter.form.details.postalCodeLabel',
    CITY: 'medicalcenter.form.details.cityLabel',
    COUNTRY: 'medicalcenter.form.details.countryLabel',
    PERMIT_SUB_CENTRES: 'medicalcenter.form.details.permitSubCenters',
    SMS_NOTIFICATION_PHONE_NUMBER: 'medicalcenter.form.smsNotifications.smsNotificationPhoneNumberLabel',
    ACTIVE: 'medicalcenter.form.details.activeLabel',
    ADMIN: 'medicalcenter.form.admin.label'
  },
  VALIDATION: {
    phoneNumber: 'validation.phoneNumberInvalidMessage',
    email: 'validation.emailInvalidMessage'
  },
  BUTTONS: {
    cancelButtonCaption: 'common.button.cancel'
  }
});
