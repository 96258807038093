import { makeStyles } from '@material-ui/core/styles';
import { fontWeight } from 'style';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '12px'
  },
  content: {
    padding: '16px 18px',
    '&:last-child': {
      paddingBottom: '10px'
    }
  },
  prop: {
    color: theme.palette.text.primary,
    fontWeight: fontWeight.medium
  },
  text: {
    color: theme.palette.text.secondary,
    marginLeft: '5px'
  },
  textError: {
    color: theme.palette.error.main,
    marginLeft: '5px'
  },
  badge: {
    marginRight: '5px',
    marginLeft: '5px',
    display: 'inline-block'
  }
}));
