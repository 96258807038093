import React, { useMemo } from 'react';
import { SelectInput } from 'react-admin';

interface ActiveFilterDropdownProps {
  source: string;
  alwaysOn?: boolean;
  allowEmpty?: boolean;
  className?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  label?: string;
  trueLabel?: string;
  falseLabel?: string;
}

export function ActiveFilterDropdown({
  className,
  source,
  alwaysOn,
  variant,
  label = 'common.active.label',
  allowEmpty,
  trueLabel = 'common.active.active',
  falseLabel = 'common.active.notActive'
}: ActiveFilterDropdownProps) {
  const statusFilterDropdownChoices = useMemo(
    () => [
      { id: 'true', name: trueLabel },
      { id: 'false', name: falseLabel }
    ],
    [trueLabel, falseLabel]
  );

  return (
    <SelectInput
      allowEmpty={allowEmpty}
      variant={variant}
      label={label}
      helperText={''}
      className={className}
      source={source}
      alwaysOn={alwaysOn}
      choices={statusFilterDropdownChoices}
    />
  );
}
