import React, { useEffect, useMemo, useRef, useState } from 'react';
import Chartjs from 'chart.js';
import 'chartjs-plugin-annotation';
import { Box, Button, Card, Grid, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { LinearProgress, useDataProvider, useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { constProvider } from 'providers';

import { useStyles } from './chart.styles';
import { chartConsts } from './chart.const';
import { Measurement, MeasurementDetailTypeEnum, MeasurementType } from '../index';
import { measurementDetailConsts } from '../measurement-detail.const';
import { cond, constant, matches, sortBy } from 'lodash';
import { colors } from 'style';
import { formatMeasurement } from 'services/utils';

interface MeasurementChartProps {
  readonly patientId: string;
  readonly measurementType: MeasurementDetailTypeEnum;
}

interface MeasurementDetailsFilters {
  readonly patientId: string;
  readonly measurementType: MeasurementType;
  examDateFrom?: string;
  examDateTo?: string;
}

const inputLabelProps = { shrink: true };

export function Chart({ patientId, measurementType }: MeasurementChartProps) {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const chartContainer = useRef<HTMLCanvasElement>(null);
  const classes = useStyles(useMemo(() => ({ color: getLineColor(measurementType) }), [measurementType]));

  const [measurements, setMeasurements] = useState<null | undefined | Measurement[]>(undefined);
  const [thresholds, setThresholds] = useState<null | undefined | Map<string, number>>(undefined);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  useEffect(() => {
    dataProvider
      .getCustomUrl(`measurement/${measurementType}/threshold`)
      .then(({ data }: { data: Array<{ valueFrom: number; valueTo: number; status: string }> }) => {
        setThresholds(
          (data || []).reduce((acc, { status, valueFrom, valueTo }) => {
            acc.set(status, measurementType === MeasurementDetailTypeEnum.SATURATION ? valueTo : valueFrom);
            return acc;
          }, new Map<string, number>())
        );
      })
      .catch(() => {
        setThresholds(null);
      });
  }, [dataProvider, measurementType]);

  useEffect(() => {
    const filter: MeasurementDetailsFilters = { patientId, measurementType };

    if (startDate !== '') {
      filter.examDateFrom = startDate;
    }

    if (endDate !== '') {
      filter.examDateTo = endDate;
    }

    dataProvider
      .getList(constProvider.RESOURCES.MEASUREMENTS_DETAILS.URI, {
        filter
      })
      .then(({ data }: { data: Measurement[] }) => {
        setMeasurements(sortBy(data, ['creationDate']));
      })
      .catch(() => {
        setMeasurements(null);
      });
  }, [measurementType, dataProvider, patientId, startDate, endDate]);

  useEffect(() => {
    if (measurements && thresholds) {
      const chartConfig = {
        type: 'line',
        data: {
          datasets: [
            {
              data: transformFormData(measurements),
              borderWidth: 2,
              fill: false,
              lineTension: 0,
              borderColor: getLineColor(measurementType)
            }
          ]
        },
        options: {
          plugins: {
            datalabels: {
              display: false
            }
          },
          tooltips: {
            callbacks: {
              title: () => {
                return '';
              },
              label: (tooltipItem: any, item: any) => {
                const value = item.datasets[0]['data'][tooltipItem['index']]['y'] || '';
                const date = item.datasets[0]['data'][tooltipItem['index']]['t'] || '';
                return `${formatMeasurement(value, measurementType)} ${dateFormat(date)}`;
              }
            },
            backgroundColor: '#424242',
            bodyFontColor: '#fff',
            displayColors: false,
            cornerRadius: 3
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  borderDash: [4, 6],
                  color: '#e9ebf1'
                },
                id: 'y-axis-0',
                ticks: {
                  callback: function (value: number) {
                    return formatMeasurement(value, measurementType);
                  }
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  borderDash: [4, 6],
                  color: '#e9ebf1'
                },
                id: 'x-axis-0',
                type: 'time'
              }
            ]
          },
          annotation: {
            annotations: [...getAnnotations(thresholds)]
          }
        }
      };

      if (chartContainer && chartContainer.current) {
        new Chartjs(chartContainer.current, chartConfig);
      }
    }
  }, [measurements, chartContainer, measurementType, thresholds]);

  function onSubmit(data: { startDate: string | undefined; endDate: string | undefined }) {
    setStartDate(data.startDate || '');
    setEndDate(data.endDate || '');
  }

  if (null === measurements || null === thresholds) {
    return null;
  }

  if (undefined === measurements || undefined === thresholds) {
    return <LinearProgress />;
  }

  return (
    <Card>
      <Box className={classes.legend}>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              <Field
                name="startDate"
                render={({ input }) => (
                  <TextField
                    {...input}
                    type="datetime-local"
                    label={translate(chartConsts.INPUT_FROM)}
                    InputLabelProps={inputLabelProps}
                    className={classes.dateInput}
                  />
                )}
              />

              <Field
                name="endDate"
                render={({ input }) => (
                  <TextField
                    {...input}
                    type="datetime-local"
                    label={translate(chartConsts.INPUT_TO)}
                    InputLabelProps={{ shrink: true }}
                    className={classes.dateInput}
                  />
                )}
              />

              <Button color="primary" variant="contained" type="submit">
                {translate(chartConsts.BUTTON_SEARCH)}
              </Button>
            </form>
          )}
        </Form>
        <Grid container className={classes.items}>
          <Typography variant="body1" className={classes.mediumText}>
            {startDate || endDate ? translate(chartConsts.CHART_TITLE) : ' '}
            {startDate ? ` ${translate(chartConsts.CHART_TITLE_FROM)} ${dateFormat(startDate)}` : ''}
            {endDate ? ` ${translate(chartConsts.CHART_TITLE_TO)} ${dateFormat(endDate)}` : ''}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <span className={classes.line}></span>
            <Typography className={classes.title}>{translate(getSettings(measurementType).legend)}</Typography>
          </Box>
        </Grid>
      </Box>
      <canvas ref={chartContainer} />
    </Card>
  );
}

function transformFormData(measurements: Measurement[]) {
  return measurements.map(({ creationDate, value }) => {
    return {
      t: moment(creationDate).toDate(),
      y: Number(value)
    };
  });
}

function dateFormat(date: any) {
  return moment(date).format(constProvider.DATE_TIME_FORMAT);
}

function getSettings(measurementType: MeasurementType) {
  const { CHART } = measurementDetailConsts;
  return cond([
    [matches(MeasurementDetailTypeEnum.PULSE), constant({ legend: CHART.PULSE })],
    [matches(MeasurementDetailTypeEnum.SATURATION), constant({ legend: CHART.SATURATION })],
    [matches(MeasurementDetailTypeEnum.TEMPERATURE), constant({ legend: CHART.TEMPERATURE })]
  ])(measurementType);
}

interface Annotation {
  type: 'line';
  id: string;
  mode: 'horizontal';
  scaleID: string;
  value: number;
  borderColor: string;
  borderWidth: number;
}

export function getAnnotations(tresholds: Map<string, number>): Annotation[] {
  return [...tresholds.keys()]
    .map((color, i) => {
      const lineColor = getTresholdLineColor(color);
      return null === lineColor
        ? null
        : {
            type: 'line' as 'line',
            id: `hline${i}`,
            mode: 'horizontal' as 'horizontal',
            scaleID: 'y-axis-0',
            value: tresholds.get(color),
            borderColor: lineColor,
            borderWidth: 1
          };
    })
    .filter((value) => value !== null) as Annotation[];
}

const getTresholdLineColor = cond([
  [matches('RED'), constant(colors.red)],
  [matches('YELLOW'), constant(colors.yellow)],
  [matches('GREEN'), constant(colors.listGreen)],
  [matches('BLUE'), constant(colors.primaryBlue)],
  [() => true, constant(null)]
]);

const getLineColor = cond([
  [matches('TEMPERATURE'), constant(colors.green)],
  [matches('SATURATION'), constant(colors.primaryBlue)],
  [matches('PULSE'), constant(colors.primaryBlueDark)]
]);
