import { FunctionField } from 'react-admin';
import React, { useCallback } from 'react';
import { Switch, CircularProgress } from '@material-ui/core';
import { noop, stubFalse } from 'lodash';

type SwitchFieldProps<M> = {
  source: keyof M;
  label?: string;
  onChange?: (record: M, checked: boolean) => void;
  showLoading?: (record: M) => boolean;
  className?: string;
};

export function SwitchField<M = any>({
  source,
  showLoading = stubFalse,
  onChange = noop,
  className = '',
  ...restProps
}: SwitchFieldProps<M>) {
  const handleClick = useCallback(
    (record: M) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      e.preventDefault();
      onChange(record, checked);
    },
    [onChange]
  );

  const render = useCallback(
    (record: M) =>
      showLoading(record) ? (
        <CircularProgress size={20} />
      ) : (
        <Switch className={className} onChange={handleClick(record)} checked={Boolean(record[source])} />
      ),
    [source, handleClick, className, showLoading]
  );

  return <FunctionField source={source} render={render} {...restProps} />;
}
