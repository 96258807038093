import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { Edit, useTranslate } from 'react-admin';
import { Tabs, Tab } from '@material-ui/core';
import * as Types from 'AppTypes';
import { getDevice } from 'features/resources/selectors';
import { PageContentWithTitle, TabPanel, EditContentWrapper } from 'components/common';
import { deviceConsts } from '../Device.const';
import { DeviceForm } from './device-form/DeviceForm';
import { DeviceMeasuringDevice } from './device-measuring-device/device-measuring-device';
import { DeviceCommands } from './device-commands/device-commands';
import { DeviceProgramming } from './device-programming/device-programming';

enum TAB {
  PARAMETERS = 'PARAMETERS',
  HISTORY = 'HISTORY',
  MEASURING_DEVICES = 'MEASURING_DEVICES',
  PROGRAMMING = 'PROGRAMMING',
  COMMANDS = 'COMMANDS'
}

export function DeviceEdit(props: any) {
  const translate = useTranslate();
  const device = useSelector((state: Types.RootState) => getDevice(state, props.id));
  const [selectedTabValue, setSelectedTabValue] = useState<TAB>(TAB.PARAMETERS);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: TAB) => {
      setSelectedTabValue(newValue);
    },
    [setSelectedTabValue]
  );

  const titleInterpolation = useMemo(() => ({ name: device?.serialNumber }), [device]);

  const renderContent = (editProps: any) => {
    return (
      <>
        <Tabs
          value={selectedTabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="Device tabs"
        >
          <Tab label={translate(deviceConsts.TABS.PARAMETERS_TAB)} value={TAB.PARAMETERS} />
          <Tab label={translate(deviceConsts.TABS.HISTORY_TAB)} disabled value={TAB.HISTORY} />
          <Tab label={translate(deviceConsts.TABS.MEASURING_DEVICES_TAB)} value={TAB.MEASURING_DEVICES} />
          <Tab label={translate(deviceConsts.TABS.PROGRAMMING_TAB)} value={TAB.PROGRAMMING} />
          <Tab label={translate(deviceConsts.TABS.COMMANDS_TAB)} value={TAB.COMMANDS} />
        </Tabs>
        <TabPanel index={TAB.PARAMETERS} value={selectedTabValue}>
          <DeviceForm {...editProps} />
        </TabPanel>
        <TabPanel index={TAB.MEASURING_DEVICES} value={selectedTabValue}>
          <DeviceMeasuringDevice {...editProps} />
        </TabPanel>
        <TabPanel index={TAB.PROGRAMMING} value={selectedTabValue}>
          <DeviceProgramming {...editProps} />
        </TabPanel>
        <TabPanel index={TAB.COMMANDS} value={selectedTabValue}>
          <DeviceCommands {...editProps} />
        </TabPanel>
      </>
    );
  };

  return (
    <PageContentWithTitle
      title={!isNil(device) ? deviceConsts.EDIT_HEADER : undefined}
      titleInterpolation={titleInterpolation}
    >
      <Edit {...props} title={translate(deviceConsts.EDIT_TITLE)}>
        <EditContentWrapper>{(editProps: any) => renderContent(editProps)}</EditContentWrapper>
      </Edit>
    </PageContentWithTitle>
  );
}
