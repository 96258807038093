import { Tab, Tabs } from '@material-ui/core';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit, useTranslate, Loading } from 'react-admin';
import { useSelector } from 'react-redux';
import * as Types from 'AppTypes';
import { EditContentWrapper, PageContentWithTitle, TabPanel } from 'components/common';
import { getPatient } from 'features/resources/selectors';
import { usePermissionsMap } from 'hooks/common';
import { PatientForm } from '../common/patient-form/PatientForm';
import { patientConsts } from '../Patient.const';
import { PatientCard } from './patient-card/patient-card';
import { PatientDashboard } from './patient-dashboard/patient-dashboard';
import { PatientDevice } from './patient-device/patient-device';
import { PatientLocalization } from './patient-localization/patient-localization';
import { useLocation } from 'react-router-dom';
import { constProvider } from 'providers';

enum TABS {
  DETAILS = 'DETAILS',
  DASHBOARD = 'DASHBOARD',
  CARD = 'CARD',
  LOCALIZATION = 'LOCALIZATION',
  DEVICES = 'DEVICES'
}

export function PatientEdit(props: any) {
  const { id } = props;
  const translate = useTranslate();
  const patient = useSelector((state: Types.RootState) => getPatient(state, id));
  const permissions = usePermissionsMap();

  const [selectedTabValue, setSelectedTabValue] = useState<TABS | null>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    if (new RegExp(`^/${constProvider.RESOURCES.PATIENT.URI}/[0-9]+/location$`).test(pathname)) {
      setSelectedTabValue(TABS.LOCALIZATION);
    } else if (permissions.size > 0) {
      setSelectedTabValue(
        permissions.any('DASHBOARDS_PATIENT_DASHBOARD_READ_ONLY_PROPERTIES') ? TABS.DASHBOARD : TABS.DETAILS
      );
    }
  }, [permissions, pathname]);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: TABS) => {
      setSelectedTabValue(newValue);
    },
    [setSelectedTabValue]
  );

  const titleInterpolation = useMemo(
    () => ({ name: patient ? `${patient.firstName} ${patient.lastName}` : undefined }),
    [patient]
  );

  const renderContent = (editProps: any) => {
    return (
      <>
        <Tabs
          value={selectedTabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="Patient tabs"
        >
          <Tab label={translate(patientConsts.TAB.PATIENT_DETAILS.LABEL)} value={TABS.DETAILS} />
          <Tab
            label={translate(patientConsts.TAB.DASHBOARD.LABEL)}
            disabled={!permissions.any('DASHBOARDS_PATIENT_DASHBOARD_READ_ONLY_PROPERTIES')}
            value={TABS.DASHBOARD}
          />
          <Tab
            label={translate(patientConsts.TAB.CARD.LABEL)}
            disabled={
              !permissions.any([
                'SECTION_PATIENTS_LIST_VIEW',
                'SPECIFIC_PATIENT_CARD_ADD',
                'SPECIFIC_PATIENT_CARD_EDIT'
              ])
            }
            value={TABS.CARD}
          />
          <Tab
            label={translate(patientConsts.TAB.LOCALIZATION.LABEL)}
            disabled={
              !permissions.any([
                'SECTION_PATIENTS_LIST_VIEW',
                'SPECIFIC_DEFINE_GEOLOCATION_AREA_ADD',
                'SPECIFIC_DEFINE_GEOLOCATION_AREA_EDIT'
              ])
            }
            value={TABS.LOCALIZATION}
          />
          <Tab
            label={translate(patientConsts.TAB.DEVICES.LABEL)}
            disabled={
              !permissions.any([
                'SECTION_PATIENTS_LIST_VIEW',
                'SPECIFIC_DEVICE_ASSIGNMENT_ADD',
                'SPECIFIC_DEVICE_ASSIGNMENT_EDIT'
              ])
            }
            value={TABS.DEVICES}
          />
        </Tabs>
        <TabPanel index={TABS.DETAILS} value={selectedTabValue as TABS}>
          <PatientForm isEditMode {...editProps} />
        </TabPanel>
        {permissions.any(
          'DASHBOARDS_PATIENT_DASHBOARD_READ_ONLY_PROPERTIES',
          <TabPanel index={TABS.DASHBOARD} value={selectedTabValue as TABS}>
            <PatientDashboard {...editProps} />
          </TabPanel>
        )}
        {permissions.any(
          ['SECTION_PATIENTS_LIST_VIEW', 'SPECIFIC_PATIENT_CARD_ADD', 'SPECIFIC_PATIENT_CARD_EDIT'],
          <TabPanel index={TABS.CARD} value={selectedTabValue as TABS}>
            <PatientCard {...editProps} />
          </TabPanel>
        )}
        {permissions.any(
          [
            'SECTION_PATIENTS_LIST_VIEW',
            'SPECIFIC_DEFINE_GEOLOCATION_AREA_ADD',
            'SPECIFIC_DEFINE_GEOLOCATION_AREA_EDIT'
          ],
          <TabPanel index={TABS.LOCALIZATION} value={selectedTabValue as TABS}>
            <PatientLocalization {...editProps} />
          </TabPanel>
        )}
        {permissions.any(
          ['SECTION_PATIENTS_LIST_VIEW', 'SPECIFIC_DEVICE_ASSIGNMENT_ADD', 'SPECIFIC_DEVICE_ASSIGNMENT_EDIT'],
          <TabPanel index={TABS.DEVICES} value={selectedTabValue as TABS}>
            <PatientDevice {...editProps} />
          </TabPanel>
        )}
      </>
    );
  };

  if (selectedTabValue === null || permissions.size === 0) {
    return <Loading />;
  }

  return (
    <PageContentWithTitle
      title={!isNil(patient) ? patientConsts.EDIT_TITLE : undefined}
      titleInterpolation={titleInterpolation}
    >
      <Edit {...props} title={translate(patientConsts.PAGE_TITLE)}>
        <EditContentWrapper>{(editProps: any) => renderContent(editProps)}</EditContentWrapper>
      </Edit>
    </PageContentWithTitle>
  );
}
