import React from 'react';
import { DateInput, Filter } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { ActiveFilterDropdown } from 'components/common';
import { listCardFiltersConsts } from './list-card-filters.const';

const useStyles = makeStyles({
  input: {
    minWidth: '220px',
    marginBottom: '20px'
  }
});

export function ListCardFilters(props: any) {
  const classes = useStyles();

  return (
    <Filter {...props}>
      <DateInput
        label={listCardFiltersConsts.FIELDS.CREATION_DATE}
        className={classes.input}
        source="creationDate"
        alwaysOn
      />
      <DateInput
        label={listCardFiltersConsts.FIELDS.DESCRIPTION_DATE}
        className={classes.input}
        source="descriptionDate"
        alwaysOn
      />
      <ActiveFilterDropdown
        label={listCardFiltersConsts.FIELDS.IS_IMPORTANT}
        trueLabel={listCardFiltersConsts.LABELS.TRUE}
        falseLabel={listCardFiltersConsts.LABELS.FALSE}
        className={classes.input}
        source="isImportant"
        alwaysOn
      />
    </Filter>
  );
}
