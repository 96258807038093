import React, { useCallback } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import {
  TextInput,
  SelectInput,
  BooleanInput,
  DateInput,
  required,
  email,
  useTranslate,
  useRedirect,
  SaveButton,
  useMutation,
  useNotify,
  ReferenceInput
} from 'react-admin';
import { Box, Grid, Toolbar, Typography, Button } from '@material-ui/core';
import { constProvider } from 'providers';
import { peselValidation, validatePhoneNumber } from 'services/validators';
import { patientConsts } from '../../Patient.const';
import { PatientFormValues } from './PatientForm.models';
import { useStyles } from './PatientForm.styles';
import { patientFormConsts } from './PatientForm.const';
import classnames from 'classnames';
import { formatPhoneNumber } from 'services/utils';

type PatientFormProps = {
  isEditMode?: boolean;
  resource?: string;
} & any;

const genderChoices = Object.entries(constProvider.GENDER_TO_LABEL).map(([id, name]) => ({ id, name }));

export function PatientForm(props: PatientFormProps) {
  const { isEditMode, basePath, record, version, resource } = props;
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const classes = useStyles();
  const [mutate] = useMutation();
  const notify = useNotify();

  const onCancelClick = useCallback(() => {
    redirectTo(`/${constProvider.RESOURCES.PATIENT.URI}`);
  }, [redirectTo]);

  const onBackClick = useCallback(() => {
    window.history.back();
  }, []);

  const phoneNumberValidator = useCallback(
    (value) => validatePhoneNumber(value, translate(patientFormConsts.VALIDATION.phoneNumber)),
    [translate]
  );

  const submit = useCallback(
    (values: PatientFormValues, api) => {
      const formState = api.getState();
      if (!formState.valid) {
        return;
      }

      if (isEditMode) {
        mutate(
          {
            type: 'update',
            resource: constProvider.RESOURCES.PATIENT.URI,
            payload: {
              id: values.id,
              data: { ...values, validatePesel: values.validatePesel || false } as PatientFormValues
            }
          },
          {
            onSuccess: ({ data: newRecord }: any) => {
              notify('ra.notification.updated', 'info', {
                smart_count: 1
              });
              redirectTo('edit', basePath, newRecord.id);
            }
          }
        );
      } else {
        mutate(
          {
            type: 'create',
            resource: constProvider.RESOURCES.PATIENT.URI,
            payload: {
              data: { ...values, validatePesel: values.validatePesel || false } as PatientFormValues
            }
          },
          {
            onSuccess: ({ data: newRecord }: any) => {
              notify('ra.notification.created', 'info', {
                smart_count: 1
              });
              redirectTo('list', basePath);
            }
          }
        );
      }
    },
    [redirectTo, basePath, isEditMode, mutate, notify]
  );

  const renderForm = (formProps: FormRenderProps<PatientFormValues>) => {
    return (
      <form autoComplete={'false'} autoCorrect={'false'}>
        <Box className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                {translate(patientConsts.TAB.PATIENT_DETAILS.LABEL)}:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <BooleanInput className={classes.pullRight} resource={resource} margin="none" source="active" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput margin="none" validate={required()} resource={resource} source="firstName" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextInput margin="none" validate={required()} resource={resource} source="lastName" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <BooleanInput resource={resource} margin="none" source="validatePesel" />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                margin="none"
                validate={[required(), conditionalPeselValidator]}
                resource={resource}
                source="pesel"
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <DateInput margin="none" validate={required()} resource={resource} source="birthday" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextInput margin="none" validate={required()} resource={resource} source="address" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput validate={required()} resource={resource} source="postCode" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput validate={required()} resource={resource} source="city" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput validate={required()} resource={resource} source="country" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                validate={phoneNumberValidator}
                resource={resource}
                source="phoneNumber"
                placeholder="+481234567890"
                parse={formatPhoneNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                validate={email(translate(patientFormConsts.VALIDATION.email))}
                resource={resource}
                source="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                validate={required()}
                resource={resource}
                source="gender"
                alwaysOn
                fullWidth
                choices={genderChoices}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                {translate(patientFormConsts.MEDICALCENTER_SUBTITLE)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ReferenceInput
                className={classes.typeInput}
                resource={resource}
                source="medicalCenterId"
                validate={required()}
                reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
              >
                <SelectInput alwaysOn />
              </ReferenceInput>
            </Grid>
          </Grid>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            {!isEditMode && (
              <Button
                color="primary"
                className={classnames(classes.previousButton, classes.flexStart)}
                onClick={onBackClick}
              >
                {translate(patientFormConsts.BUTTONS.backButtonCaption)}
              </Button>
            )}
            <Button
              color="primary"
              className={classnames(classes.cancelButton, classes.autoMarginLeft)}
              onClick={onCancelClick}
            >
              {translate(patientFormConsts.BUTTONS.cancelButtonCaption)}
            </Button>
            <SaveButton saving={formProps.submitting} handleSubmitWithRedirect={formProps.handleSubmit} />
          </Box>
        </Toolbar>
      </form>
    );
  };

  return (
    <Form
      initialValues={record}
      onSubmit={submit}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      render={renderForm}
    />
  );
}

function conditionalPeselValidator(value: string | undefined, allValues: PatientFormValues): string | undefined {
  return allValues.validatePesel ? peselValidation(value) : undefined;
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
};
