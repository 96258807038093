import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField as TextInput,
  Typography
} from '@material-ui/core';
import { Timelapse as TimelapseIcon } from '@material-ui/icons';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useMemo, useState } from 'react';
import { Datagrid, FunctionField, ReferenceField, TextField, useCreate, useRefresh, useTranslate } from 'react-admin';
import { List, ListPickerColumns } from 'components/common';
import { constProvider } from 'providers';
import { defaultListProps } from 'services/utils';
import { patientCardSectionConsts } from './patient-card-section.const';
import { useStyles } from './patient-card-section.styles';

export type FormField<T extends object = any> = {
  source: keyof T;
  label?: string;
  type: 'date' | 'string' | 'number' | 'file' | 'hidden';
  cols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  align?: 'left' | 'center' | 'right';
  options?: { code: string; label: string }[];
  multiple?: boolean;
  defaultValue?: T[keyof T];
};

type Props<T extends object> = {
  className?: string;
  listLabel: keyof typeof patientCardSectionConsts;
  label: keyof typeof patientCardSectionConsts;
  listColumns: ListPickerColumns<T>;
  formFields: FormField<T>[];
  resourceName: keyof typeof constProvider.RESOURCES;
  patientId?: number;
  filterDefaultValues?: object;
};

function createNewItem<T extends object>(formFields: FormField<T>[]) {
  return Object.fromEntries(formFields.map(({ defaultValue, source }) => [source, defaultValue])) as T;
}

export function PatientCardSection<T extends object>({
  className,
  label,
  listLabel,
  resourceName,
  listColumns,
  patientId,
  formFields,
  filterDefaultValues = {}
}: Props<T>) {
  const classes = useStyles();
  const translate = useTranslate();
  const refresh = useRefresh();

  const uri = constProvider.RESOURCES[resourceName].URI;

  const listProps = {
    ...defaultListProps,
    basePath: `/${constProvider.RESOURCES.PATIENT.URI}`,
    resource: uri,
    filters: null,
    pagination: null,
    filterDefaultValues
  };
  const [newItem, setNewItem] = useState<T>(createNewItem(formFields));

  const rowSelectable = useCallback(() => false, []);
  const onChange = useCallback(({ target: { value, name } }) => setNewItem({ ...newItem, [name]: value }), [
    newItem,
    setNewItem
  ]);

  const [create, { loading: saving }] = useCreate(`${uri}${patientId ? `/${patientId}` : ''}`, newItem, {
    onSuccess() {
      setNewItem(createNewItem(formFields));
      refresh();
    }
  });

  const columns = useMemo(
    () =>
      listColumns.map(({ source, resource, refSource, render }) => {
        return resource ? (
          <ReferenceField
            key={source}
            variant="standard"
            source={source}
            reference={constProvider.RESOURCES[resource].URI}
          >
            {render ? <FunctionField render={render} /> : <TextField source={refSource} />}
          </ReferenceField>
        ) : render ? (
          <FunctionField render={render} />
        ) : (
          <TextField key={source} source={source} />
        );
      }),
    [listColumns]
  );

  const fields = useMemo(
    () =>
      formFields
        .filter(({ type }) => type !== 'hidden')
        .map(({ type, source, label = `resources.${uri}.fields.${source}`, cols, align, options, multiple }) => (
          <Grid key={source as string} item xs={cols || 12} {...({ align } as any)}>
            {options ? (
              <Select
                fullWidth
                value={newItem[source]}
                name={source as string}
                label={translate(label)}
                multiple={multiple}
                renderValue={(selected) => [].concat(selected as any).join(', ')}
                onChange={onChange}
              >
                {options.map(({ code, label }) => (
                  <MenuItem key={code} value={code}>
                    <Checkbox checked={(newItem?.[source] as any)?.includes(code)} />
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            ) : type === 'date' ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  ampm={false}
                  variant="inline"
                  format="dd/MM/yyyy HH:mm"
                  value={newItem[source] as any}
                  label={translate(label)}
                  onChange={(value) => onChange({ target: { name: source, value } })}
                />
              </MuiPickersUtilsProvider>
            ) : (
              <TextInput
                value={newItem[source]}
                fullWidth
                name={source as string}
                label={translate(label)}
                type={type}
                onChange={onChange}
              />
            )}
          </Grid>
        )),
    [formFields, uri, newItem, translate, onChange]
  );
  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <Typography component="h2" className={classes.header}>
          {translate(patientCardSectionConsts[listLabel])}
        </Typography>
        <List {...listProps}>
          <Datagrid isRowSelectable={rowSelectable}>{columns}</Datagrid>
        </List>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6" component="h2" className={classes.header}>
              {translate(patientCardSectionConsts[label])}
            </Typography>
          </Grid>
          {fields}
          <Grid item xs={12}>
            <Button className={classes.save} disabled={saving} onClick={create} variant="contained" color="primary">
              {saving ? <TimelapseIcon /> : <Typography>Zapisz</Typography>}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
