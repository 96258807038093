import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { composeEnhancers } from './utils';
import rootReducer from './root-reducer';
import rootEpic from './root-epic';
import rootSaga from './root-saga';
import { authProvider, historyProvider } from 'providers';
import Types from 'AppTypes';
import { DataProvider } from 'ra-core';

const epicMiddleware = createEpicMiddleware<Types.RootAction, Types.RootAction, Types.RootState>();
const routerMiddleware = createRouterMiddleware(historyProvider);
const sagaMiddleware = createSagaMiddleware();

// configure middlewares
const middlewares = [epicMiddleware, sagaMiddleware, routerMiddleware];

// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {
  admin: {
    ui: {
      // default state copied from node_modules/ra-core/src/reducer/admin/ui.ts, without it app may break
      isSidebarOpen: false,
      optimistic: false,
      viewVersion: 0
    }
  }
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['selectedMedicalcenter']
};

// create store
export const store = createStore(persistReducer(persistConfig, rootReducer), initialState, enhancer);

export function runStore(dataProvider: DataProvider) {
  epicMiddleware.run(rootEpic);
  sagaMiddleware.run(rootSaga(authProvider, dataProvider));
  return store;
}
