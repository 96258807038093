export const listCardFiltersConsts = Object.freeze({
  FIELDS: {
    CREATION_DATE: 'resources.patient.edit.dashboard.measurement.filters.creationDate',
    DESCRIPTION_DATE: 'resources.patient.edit.dashboard.measurement.filters.descriptionDate',
    IS_IMPORTANT: 'resources.patient.edit.dashboard.measurement.filters.isImportant'
  },
  LABELS: {
    TRUE: 'resources.patient.edit.dashboard.measurement.filters.labels.true',
    FALSE: 'resources.patient.edit.dashboard.measurement.filters.labels.false'
  }
});
