import React, { useMemo, useEffect, useRef, memo, useCallback } from 'react';
import { useTranslate, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import classNames from 'classnames';
import * as Nominatim from 'nominatim-browser';
import { Collection, Feature } from 'ol';
import { fromLonLat, toLonLat } from 'ol/proj';
import { getCenter } from 'ol/extent';
import { Circle, Point, Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { TranslateEvent } from 'ol/interaction/Translate';
import { defaults as defaultInteractions, Translate } from 'ol/interaction';
// @ts-ignore
import Colorize from 'ol-ext/filter/Colorize';
import { Card, CardContent, Box, Typography } from '@material-ui/core';
import { Map } from 'components/common';
import { usePrevious } from 'hooks/common/use-previous';
import { DeviceLocationDataDTO, LocationLockDownDataDTO } from '../patient-localization-form.models';
import { FieldNames } from '../patient-localization-form.const';
import {
  MAP_PROJECTION,
  DEFAULT_CENTER_COORDINATE,
  DEFAULT_ZOOM_LEVEL,
  LOCATION_LOCKDOWN_LAYER_STYLE,
  DEVICE_LOCATION_LAYER_STYLE,
  mapCardTranslationConsts,
  REVERSE_ADDRESS_LOOKUP_ZOOM_LEVEL,
  DEVICE_OUTSIDE_LOCK_DOWN_LOCATION_FILTER
} from './map-card.const';
import {
  getCircleFeature,
  getPointFeature,
  getPointGeometry,
  getCircleGeometry,
  changeFeaturesGeometry
} from './map-card.utils';
import { useStyles } from './map-card.styles';

type MapCardProps = {
  deviceLocationData?: DeviceLocationDataDTO;
  locationLockDownData?: LocationLockDownDataDTO;
  isEditMode: boolean;
};

export const MapCard = memo(function MapCard({ locationLockDownData, deviceLocationData, isEditMode }: MapCardProps) {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const locationLockDownDataRef = useRef(locationLockDownData);
  const deviceLocationDataRef = useRef(deviceLocationData);
  const prevIsEditMode = usePrevious<boolean>(isEditMode);
  const form = useForm();

  const locationLockDownLayer = useMemo(() => {
    if (!locationLockDownDataRef.current) {
      return;
    }

    const { longitude, latitude, radius } = locationLockDownDataRef.current;
    const circleFeature = getCircleFeature(longitude, latitude, radius);
    const pointFeature = getPointFeature(longitude, latitude);

    return new VectorLayer({
      source: new VectorSource({
        features: [circleFeature, pointFeature]
      }),
      style: LOCATION_LOCKDOWN_LAYER_STYLE
    });
  }, []);

  const deviceLocationFeatures = useMemo(() => {
    if (!deviceLocationData) {
      return [];
    }

    const { longitude, latitude, positionDilutionOfPrecision } = deviceLocationData;

    return longitude && latitude && positionDilutionOfPrecision
      ? [getCircleFeature(longitude, latitude, positionDilutionOfPrecision), getPointFeature(longitude, latitude)]
      : [];
  }, [deviceLocationData]);

  const deviceLocationCenter = useMemo(() => {
    if (
      !deviceLocationData ||
      (deviceLocationData && (!deviceLocationData.longitude || !deviceLocationData.latitude))
    ) {
      return fromLonLat(DEFAULT_CENTER_COORDINATE, MAP_PROJECTION);
    }

    return fromLonLat([deviceLocationData.longitude, deviceLocationData.latitude], MAP_PROJECTION);
  }, [deviceLocationData]);

  const center = useMemo(() => {
    return isEditMode && locationLockDownData && locationLockDownData.longitude && locationLockDownData.latitude
      ? fromLonLat([locationLockDownData.longitude, locationLockDownData.latitude], MAP_PROJECTION)
      : deviceLocationCenter;
  }, [isEditMode, locationLockDownData, deviceLocationCenter]);

  const setReverseGeocodeAddress = useCallback(
    (lon: number, lat: number) => {
      return Nominatim.reverseGeocode({
        lon: String(lon),
        lat: String(lat),
        zoom: REVERSE_ADDRESS_LOOKUP_ZOOM_LEVEL
      })
        .then((result: Nominatim.NominatimResponse) => {
          return result;
        })
        .catch(() => {
          notify(mapCardTranslationConsts.MESSAGES.REVERSE_ADDRESS_LOOKUP_ERROR_MESSAGE, 'warning', {
            smart_count: 1
          });
        });
    },
    [notify]
  );

  const mapTranslateInteraction = useMemo(() => {
    if (!locationLockDownLayer || !locationLockDownData) {
      return;
    }

    const translateInteraction = new Translate({
      features: new Collection<Feature<Geometry>>(locationLockDownLayer.getSource().getFeatures())
    });

    translateInteraction.on('translating', (evt: TranslateEvent) => {
      const translatingFeature = evt.features.item(0);
      const translatingFeatureCenter = getCenter(translatingFeature.getGeometry().getExtent());
      changeFeaturesGeometry(
        locationLockDownLayer,
        new Point(translatingFeatureCenter),
        new Circle(translatingFeatureCenter, Number(locationLockDownData.radius))
      );
    });

    translateInteraction.on('translateend', async (evt: TranslateEvent) => {
      const translatingFeature = evt.features.item(0);
      const translatingFeatureCenter = toLonLat(getCenter(translatingFeature.getGeometry().getExtent()));
      const reverseAddress = await setReverseGeocodeAddress(translatingFeatureCenter[0], translatingFeatureCenter[1]);

      if (!reverseAddress) {
        return;
      }

      form.batch(() => {
        form.change(FieldNames.longitude, translatingFeatureCenter[0]);
        form.change(FieldNames.latitude, translatingFeatureCenter[1]);
        form.change(FieldNames.address, reverseAddress.display_name);
      });
    });

    return translateInteraction;
  }, [locationLockDownLayer, locationLockDownData, form, setReverseGeocodeAddress]);

  const deviceOutsideLockDownLocationFilter = useMemo(() => {
    const colorFilter = new Colorize({
      operation: 'color',
      color: DEVICE_OUTSIDE_LOCK_DOWN_LOCATION_FILTER.COLOR,
      value: DEVICE_OUTSIDE_LOCK_DOWN_LOCATION_FILTER.VALUE
    });
    colorFilter.setActive(false);

    return colorFilter;
  }, []);

  const changeDeviceOutsideLockDownLocationFilter = useCallback(
    (newCircleGeometry: Circle) => {
      const deviceLocationDataInit = deviceLocationDataRef.current;

      if (deviceLocationDataInit && deviceLocationDataInit.longitude && deviceLocationDataInit.latitude) {
        const isDeviceInsideLocationLockdown = newCircleGeometry.intersectsCoordinate(
          fromLonLat([deviceLocationDataInit.longitude, deviceLocationDataInit.latitude], MAP_PROJECTION)
        );
        deviceOutsideLockDownLocationFilter.setActive(!isDeviceInsideLocationLockdown);
      }
    },
    [deviceOutsideLockDownLocationFilter]
  );

  useEffect(() => {
    if (!mapTranslateInteraction || isEditMode === prevIsEditMode) {
      return;
    }

    mapTranslateInteraction.setActive(isEditMode);
  }, [mapTranslateInteraction, isEditMode, prevIsEditMode]);

  useEffect(() => {
    if (!locationLockDownLayer || !locationLockDownData) {
      return;
    }

    const newCircleGeometry = getCircleGeometry(
      locationLockDownData.longitude,
      locationLockDownData.latitude,
      Number(locationLockDownData.radius)
    );

    changeFeaturesGeometry(
      locationLockDownLayer,
      getPointGeometry(locationLockDownData.longitude, locationLockDownData.latitude),
      newCircleGeometry
    );

    changeDeviceOutsideLockDownLocationFilter(newCircleGeometry);
  }, [locationLockDownLayer, locationLockDownData, changeDeviceOutsideLockDownLocationFilter]);

  return (
    <Card>
      <CardContent className={classes.content}>
        <Map
          className={classes.map}
          view={{
            center,
            zoom: DEFAULT_ZOOM_LEVEL,
            projection: MAP_PROJECTION
          }}
          layers={[
            ...(locationLockDownLayer ? [locationLockDownLayer] : []),
            new VectorLayer({
              source: new VectorSource({
                features: deviceLocationFeatures
              }),
              style: DEVICE_LOCATION_LAYER_STYLE
            })
          ]}
          interactions={defaultInteractions()
            .extend(mapTranslateInteraction ? [mapTranslateInteraction] : [])
            .getArray()}
          filter={deviceOutsideLockDownLocationFilter}
        />
        <Box display="flex" flexDirection="row" p={1}>
          <Box display="flex" flexDirection="row" p={0.875}>
            <hr className={classNames(classes.legendSymbol, classes.locationLockdownLegendSymbol)} />{' '}
            <Typography variant="body2">{translate(mapCardTranslationConsts.LEGEND.LOCATION_LOCK_DOWN)}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" p={0.875}>
            <hr className={classNames(classes.legendSymbol, classes.deviceLocaionLegendSymbol)} />{' '}
            <Typography variant="body2">{translate(mapCardTranslationConsts.LEGEND.DEVICE_LOCATION)}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
});
