import React, { useCallback, useState } from 'react';
import { Datagrid, FunctionField, TextField, useTranslate, ReferenceField } from 'react-admin';
import { constProvider } from 'providers';
import { CreateInstanceAside, PageContentWithTitle, List, BooleanField } from 'components/common';
import { usePermissionsMap } from 'hooks/common';
import { UsersFormValues } from '../common/user-form/UsersForm.models';
import { FiltersButton } from 'components/common/FiltersButton/FiltersButton';
import { userConsts } from '../User.const';
import { UserFilters } from './user-filters/UserFilters';

type UserListProps = any;

export function UserList(props: UserListProps) {
  const translate = useTranslate();

  const [showFilters, setShowFilters] = useState(false);
  const permissions = usePermissionsMap();

  const toggleFilters = useCallback(() => setShowFilters(!showFilters), [showFilters]);

  return (
    <PageContentWithTitle title={userConsts.LIST_TITLE}>
      <List
        {...props}
        filters={showFilters ? <UserFilters /> : undefined}
        title={translate(userConsts.PAGE_TITLE)}
        bulkActionButtons={false}
        exporter={false}
        actions={<FiltersButton toggleFilters={toggleFilters} />}
        aside={permissions.any('SECTION_USERS_ADD', <CreateInstanceAside />)}
      >
        <Datagrid rowClick="edit">
          <TextField source="title" sortable={true} />
          <TextField source="firstName" sortable={true} />
          <TextField source="lastName" sortable={true} />
          <FunctionField
            source="type"
            render={({ type }: UsersFormValues) =>
              translate(userConsts.USER_ROLES[type as keyof typeof userConsts.USER_ROLES])
            }
          />
          <ReferenceField
            source="medicalCenterId"
            reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
            sortBy="medicalCenterName"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="active" sortable={true} />
          <TextField source="lastLogin" sortable={true} />
        </Datagrid>
      </List>
    </PageContentWithTitle>
  );
}
