import React, { useState, useCallback } from 'react';
import { Datagrid, TextField, DateField, ReferenceField, useTranslate } from 'react-admin';
import { constProvider } from 'providers';
import { List, PageContentWithTitle, BooleanField } from 'components/common';
import { FiltersButton } from 'components/common/FiltersButton/FiltersButton';
import { deviceConsts } from '../Device.const';
import { DeviceFilters } from './device-filters/DeviceFilters';

export function DeviceList(props: any) {
  const translate = useTranslate();
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilter = useCallback(() => setShowFilters(!showFilters), [showFilters]);

  return (
    <PageContentWithTitle title={deviceConsts.LIST_HEADER}>
      <List
        {...props}
        filters={showFilters ? <DeviceFilters /> : undefined}
        title={translate(deviceConsts.LIST_TITLE)}
        bulkActionButtons={false}
        exporter={false}
        actions={<FiltersButton toggleFilters={toggleFilter} />}
      >
        <Datagrid rowClick="edit">
          <TextField source="serialNumber" />
          <TextField source="deviceType" />
          <TextField source="simNumber" />
          <BooleanField source="available" />
          <DateField source="lastCheck" showTime={true} />
          <ReferenceField
            source="medicalCenterId"
            reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
            sortBy="medicalCenterName"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="lastProgrammed" showTime={true} />
          <TextField source="lent" />
        </Datagrid>
      </List>
    </PageContentWithTitle>
  );
}
