import React, { useCallback } from 'react';
import { FunctionField } from 'react-admin';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { DoctorPatientDto } from 'models/models';
import { makeStyles } from '@material-ui/core/styles';
import { getMeasurementStyleForCategory } from 'services/utils';

type MeasurmentDetailsFieldProps = {
  source: string;
  getLinkTarget: (patientId: string) => string;
} & any;

const useStyles = makeStyles({
  link: {
    textDecoration: 'none'
  }
});

export function MeasurmentDetailsField({ source, getLinkTarget, ...restProps }: MeasurmentDetailsFieldProps) {
  const classes = useStyles();
  const text = get(restProps.record, `${source}.value`);
  const category = get(restProps.record, `${source}.category`);

  const renderDeviceField = useCallback(
    (props: DoctorPatientDto) => {
      return (
        <Link to={getLinkTarget(props.id)} className={classes.link} style={getMeasurementStyleForCategory(category)}>
          {text ? text : '-'}
        </Link>
      );
    },
    [classes.link, text, getLinkTarget, category]
  );
  return <FunctionField source={source} render={renderDeviceField} {...restProps} />;
}
