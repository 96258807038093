import { constProvider } from 'providers';

export const measurementConsts = Object.freeze({
  LIST_TITLE: 'resources.measurements.list.title',
  LIST_NAME: 'resources.measurements.name',
  STATUS: {
    NEW: 'common.measurementStatus.new',
    NEW_URGENT: 'common.measurementStatus.new_urgent',
    VIEWED: 'common.measurementStatus.viewed'
  }
});

export const measurementDictionary = Object.freeze({
  TYPES: [
    {
      id: constProvider.MEASUREMENT_TYPE.PULSE,
      name: `common.measurementType.pulse`
    },
    {
      id: constProvider.MEASUREMENT_TYPE.SATURATION,
      name: `common.measurementType.saturation`
    },
    {
      id: constProvider.MEASUREMENT_TYPE.TEMPERATURE,
      name: `common.measurementType.temperature`
    }
  ],
  STATUSES: [
    {
      id: constProvider.MEASUREMENT_STATUS.NEW,
      name: `common.measurementStatus.new`
    },
    {
      id: constProvider.MEASUREMENT_STATUS.NEW_URGENT,
      name: `common.measurementStatus.new_urgent`
    },
    {
      id: constProvider.MEASUREMENT_STATUS.VIEWED,
      name: `common.measurementStatus.viewed`
    }
  ]
});
