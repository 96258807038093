import {
  Business,
  DashboardSharp,
  GroupOutlined,
  InsertChartOutlined,
  PermIdentity,
  SettingsRemote,
  SurroundSound
} from '@material-ui/icons';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Admin, Resource } from 'react-admin';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { runStore, store } from 'store/store';
import { MeasurementDetailEdit } from '../resources/measurement-details';

import CustomLayout from 'components/common/CustomLayout/CustomLayout';
import {
  authProvider,
  constProvider,
  historyProvider,
  httpInstance,
  i18nProvider,
  SECTIONS_PRIVILEGES,
  simpleRestProvider
} from 'providers';
import { myTheme } from 'style';

import { NotAuthorized } from '../common/NotAuthorized/NotAuthorized';
import { Dashboard } from '../resources/dashboard/';
import { DeviceEdit, DeviceList } from '../resources/device';
import { MeasuringDeviceEdit, MeasuringDeviceList } from '../resources/measuring-device';
import { MedicalCenterCreate, MedicalCenterEdit, MedicalCenterList } from '../resources/medicalcenter';
import { PatientCreate, PatientEdit, PatientList } from '../resources/patient';
import { UserList, UserPermissions, UsersCreate, UsersEdit } from '../resources/user';
import { LoginPage } from './LoginPage/LoginPage';
import { MedicalcenterSelectionPage } from './MedicalcenterSelectionPage/MedicalcenterSelectionPage';
import { ResourceUserPermissions } from '../../types';
import { hasPermission, hasPermissionToDashboard } from '../../services/utils/permissions.utils';
import { MeasurementList } from '../resources/measurement';

export function App() {
  const persistor = useMemo(() => persistStore(store), []);

  const dataProvider = useMemo(() => {
    return simpleRestProvider(constProvider.BASE_API_URL, httpInstance, store);
  }, []);

  useEffect(() => {
    runStore(dataProvider);
  }, [dataProvider]);

  const routes = useMemo(
    () => [
      <Route
        exact
        path={constProvider.MEDICALCENTER_SELECTION_PAGE_URI}
        key={constProvider.MEDICALCENTER_SELECTION_PAGE_URI}
        component={MedicalcenterSelectionPage}
      />,
      <Route
        exact
        path={`/measurement/:patientId/:measurementType`}
        key={`/measurement/:patientId/:measurementType`}
        component={MeasurementDetailEdit}
      />
    ],
    []
  );

  const resourcesCallback = useCallback(
    (permissions: UserPermissions[]) => [
      <Resource
        key="dashboard"
        name="dashboard"
        list={hasPermissionToDashboard(permissions) ? Dashboard : NotAuthorized}
        icon={DashboardSharp}
        options={{
          ...dashboardOptions,
          ...getDashboardSectionPermissions(permissions)
        }}
      />,
      <Resource
        key={constProvider.RESOURCES.MEASUREMENT.URI}
        name={constProvider.RESOURCES.MEASUREMENT.URI}
        list={isAuthorized(permissions, 'SECTION_RESULTS_LIST_VIEW', MeasurementList)}
        icon={InsertChartOutlined}
        options={{
          ...measurementOptions,
          ...getStandardSectionPermissions(permissions, constProvider.SECTIONS_PRIVILEGES.RESULTS)
        }}
      />,
      <Resource
        key={constProvider.RESOURCES.DEVICE.URI}
        name={constProvider.RESOURCES.DEVICE.URI}
        list={isAuthorized(permissions, 'SECTION_DEVICES_LIST_VIEW', DeviceList)}
        edit={isAuthorized(permissions, 'SECTION_DEVICES_EDIT', DeviceEdit)}
        icon={SurroundSound}
        options={{
          ...deviceOptions,
          ...getStandardSectionPermissions(permissions, constProvider.SECTIONS_PRIVILEGES.DEVICES)
        }}
      />,
      <Resource
        key={constProvider.RESOURCES.MEASURING_DEVICE.URI}
        name={constProvider.RESOURCES.MEASURING_DEVICE.URI}
        list={isAuthorized(permissions, 'SECTION_MEASUREMENTS_DEVICE_LIST_VIEW', MeasuringDeviceList)}
        edit={isAuthorized(permissions, 'SECTION_MEASUREMENTS_DEVICE_EDIT', MeasuringDeviceEdit)}
        icon={SettingsRemote}
        options={{
          ...measuringDeviceOptions,
          ...getStandardSectionPermissions(permissions, constProvider.SECTIONS_PRIVILEGES.MEASUREMENTS_DEVICE)
        }}
      />,
      <Resource
        key={constProvider.RESOURCES.PATIENT.URI}
        name={constProvider.RESOURCES.PATIENT.URI}
        list={isAuthorized(permissions, 'SECTION_PATIENTS_LIST_VIEW', PatientList)}
        icon={GroupOutlined}
        edit={isAuthorized(permissions, 'SECTION_PATIENTS_EDIT', PatientEdit)}
        create={isAuthorized(permissions, 'SECTION_PATIENTS_ADD', PatientCreate)}
        options={{
          ...patientOptions,
          ...getStandardSectionPermissions(permissions, constProvider.SECTIONS_PRIVILEGES.PATIENTS)
        }}
      />,
      <Resource
        key={constProvider.RESOURCES.USER.URI}
        name={constProvider.RESOURCES.USER.URI}
        icon={PermIdentity}
        list={isAuthorized(permissions, 'SECTION_USERS_LIST_VIEW', UserList)}
        create={isAuthorized(permissions, 'SECTION_USERS_ADD', UsersCreate)}
        edit={isAuthorized(permissions, 'SECTION_USERS_EDIT', UsersEdit)}
        options={{
          ...usersOptions,
          ...getStandardSectionPermissions(permissions, constProvider.SECTIONS_PRIVILEGES.USERS)
        }}
      />,
      <Resource
        key={constProvider.RESOURCES.MEDICAL_CENTER.URI}
        name={constProvider.RESOURCES.MEDICAL_CENTER.URI}
        icon={Business}
        list={isAuthorized(permissions, 'SECTION_MEDICAL_CENTERS_LIST_VIEW', MedicalCenterList)}
        create={isAuthorized(permissions, 'SECTION_MEDICAL_CENTERS_ADD', MedicalCenterCreate)}
        edit={isAuthorized(permissions, 'SECTION_MEDICAL_CENTERS_EDIT', MedicalCenterEdit)}
        options={{
          ...medicalCenterOptions,
          ...getStandardSectionPermissions(permissions, constProvider.SECTIONS_PRIVILEGES.MEDICAL_CENTERS)
        }}
      />,
      <Resource
        name={constProvider.RESOURCES.USER_MEDICALCENTER.URI}
        key={constProvider.RESOURCES.USER_MEDICALCENTER.URI}
      />,
      <Resource name={constProvider.RESOURCES.DEVICE_TYPES.URI} key={constProvider.RESOURCES.DEVICE_TYPES.URI} />,
      <Resource
        name={constProvider.RESOURCES.MEDICALCENTER_SUBCENTER.URI}
        key={constProvider.RESOURCES.MEDICALCENTER_SUBCENTER.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.LOCATION_LOCKDOWN.URI}
        key={constProvider.RESOURCES.LOCATION_LOCKDOWN.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.PATIENT_LOCATION.URI}
        key={constProvider.RESOURCES.PATIENT_LOCATION.URI}
      />,
      <Resource name={constProvider.RESOURCES.PATIENT_DEVICE.URI} key={constProvider.RESOURCES.PATIENT_DEVICE.URI} />,
      <Resource name={constProvider.RESOURCES.DEVICE_LOCATION.URI} key={constProvider.RESOURCES.DEVICE_LOCATION.URI} />,
      <Resource
        name={constProvider.RESOURCES.DEVICE_MEASURINGDEVICE.URI}
        key={constProvider.RESOURCES.DEVICE_MEASURINGDEVICE.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.AVAILABLE_MEASURINGDEVICE.URI}
        key={constProvider.RESOURCES.AVAILABLE_MEASURINGDEVICE.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.DOCTOR_PATIENT_MEASUREMENT.URI}
        key={constProvider.RESOURCES.DOCTOR_PATIENT_MEASUREMENT.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.AVAILABLE_DEVICE.URI}
        key={constProvider.RESOURCES.AVAILABLE_DEVICE.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
        key={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.MEDICALCENTER_SUBCENTER_FILTER_SELECT.URI}
        key={constProvider.RESOURCES.MEDICALCENTER_SUBCENTER_FILTER_SELECT.URI}
      />,
      <Resource name={constProvider.RESOURCES.ROLE_PRIVILEGE.URI} key={constProvider.RESOURCES.ROLE_PRIVILEGE.URI} />,
      <Resource
        name={constProvider.RESOURCES.ADMIN_MEDICALCENTER.URI}
        key={constProvider.RESOURCES.ADMIN_MEDICALCENTER.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.DEVICE_OPERATION.URI}
        key={constProvider.RESOURCES.DEVICE_OPERATION.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.PATIENT_EXAMINATION.URI}
        key={constProvider.RESOURCES.PATIENT_EXAMINATION.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.PATIENT_DIAGNOSIS.URI}
        key={constProvider.RESOURCES.PATIENT_DIAGNOSIS.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.PATIENT_MEDICATION.URI}
        key={constProvider.RESOURCES.PATIENT_MEDICATION.URI}
      />,
      <Resource
        name={constProvider.RESOURCES.MEASUREMENTS_DETAILS.URI}
        key={constProvider.RESOURCES.MEASUREMENTS_DETAILS.URI}
      />
    ],
    []
  );

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          history={historyProvider}
          i18nProvider={i18nProvider}
          loginPage={LoginPage}
          layout={CustomLayout}
          theme={myTheme}
          customRoutes={routes}
        >
          {resourcesCallback}
        </Admin>
      </PersistGate>
    </Provider>
  );
}

function isAuthorized(
  allUserPermissions: UserPermissions[],
  key: UserPermissions,
  component: (props: any) => JSX.Element
) {
  return hasPermission(allUserPermissions, key) ? component : NotAuthorized;
}

export function getDashboardSectionPermissions(allUserPermissions: UserPermissions[]): ResourceUserPermissions {
  return {
    permissions: {
      list: hasPermissionToDashboard(allUserPermissions),
      add: false,
      delete: false,
      edit: false,
      readOnly: false
    }
  };
}

function getStandardSectionPermissions(
  allUserPermissions: UserPermissions[],
  section: SECTIONS_PRIVILEGES
): ResourceUserPermissions {
  return {
    permissions: {
      list: hasPermission(allUserPermissions, `${section}_${constProvider.PRIVILEGES.LIST_VIEW}` as UserPermissions),
      add: hasPermission(allUserPermissions, `${section}_${constProvider.PRIVILEGES.ADD}` as UserPermissions),
      delete: hasPermission(allUserPermissions, `${section}_${constProvider.PRIVILEGES.DELETE}` as UserPermissions),
      edit: hasPermission(allUserPermissions, `${section}_${constProvider.PRIVILEGES.EDIT}` as UserPermissions),
      readOnly: hasPermission(
        allUserPermissions,
        `${section}_${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}` as UserPermissions
      )
    }
  };
}

const dashboardOptions = {
  label: constProvider.RESOURCES.DASHBOARD.LABEL
};

const measurementOptions = {
  label: constProvider.RESOURCES.MEASUREMENT.LABEL
};

const deviceOptions = {
  label: constProvider.RESOURCES.DEVICE.LABEL
};

const measuringDeviceOptions = {
  label: constProvider.RESOURCES.MEASURING_DEVICE.LABEL
};

const patientOptions = {
  label: constProvider.RESOURCES.PATIENT.LABEL
};

const medicalCenterOptions = {
  label: constProvider.RESOURCES.MEDICAL_CENTER.LABEL
};

const usersOptions = {
  label: constProvider.RESOURCES.USER.LABEL
};
