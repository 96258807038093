import { MeasurementDetailTypeEnum } from 'components/resources/measurement-details';
import { cond, constant, matches } from 'lodash';
import { constProvider } from 'providers';
import { colors } from 'style';

export function getMeasurementUnit(measurementType: MeasurementDetailTypeEnum): string {
  return cond([
    [matches(MeasurementDetailTypeEnum.TEMPERATURE), constant(constProvider.CELCIUS)],
    [matches(MeasurementDetailTypeEnum.PULSE), constant('BPM')],
    [() => true, constant('')]
  ])(measurementType);
}

export function formatMeasurement(value: string | number, measurementType: MeasurementDetailTypeEnum): string {
  const precision = cond([
    [matches(MeasurementDetailTypeEnum.PULSE), constant(0)],
    [matches(MeasurementDetailTypeEnum.SATURATION), constant(0)],
    [matches(MeasurementDetailTypeEnum.TEMPERATURE), constant(1)],
    [() => true, constant(0)]
  ])(measurementType);

  return `${Number.parseFloat(value.toString()).toFixed(precision)} ${getMeasurementUnit(measurementType)}`;
}

export function getMeasurementStyleForCategory(color: string | undefined) {
  return cond([
    [matches('RED'), textColorRed],
    [matches('YELLOW'), textColorYellow],
    [matches('GREEN'), textColorGreen],
    [matches('BLUE'), textColorBlue],
    [() => true, textColorNone]
  ])(color);
}

const textColorYellow = constant({ color: colors.darkYellow });
const textColorGreen = constant({ color: colors.darkGreen });
const textColorBlue = constant({ color: colors.primaryBlueDark });
const textColorRed = constant({ color: colors.darkRed });
const textColorNone = constant({});
