// @ts-nocheck

import React from 'react';
import classnames from 'classnames';
import { Typography } from '@material-ui/core';
import { matches, constant, cond, get } from 'lodash';
import { constProvider } from 'providers';
import { PatientCategoryEnum } from 'models/models';
import { useStyles } from './patient-category-badge.styles';

type patientCategoryBadgeProps = {
  category: PatientCategoryEnum | string;
  className?: string;
  component?: keyof React.ReactHTML;
};

export function PatientCategoryBadge({ category, className, component }: patientCategoryBadgeProps) {
  // @material-ui/core does not have proper types for some of their components. Typography supports `component` property
  // but when used with a dynamic property it throws an error. To prevent that, @ts-ignore was added to both Typography
  // usages
  const classes = useStyles();

  if (!category) {
    return (
      <Typography component={component} align="center">
        -
      </Typography>
    );
  }

  const getValueStyle = cond([
    [matches(constProvider.PATIENT_CATEGORY.RED), constant(classes.red)],
    [matches(constProvider.PATIENT_CATEGORY.YELLOW), constant(classes.yellow)],
    [matches(constProvider.PATIENT_CATEGORY.GREEN), constant(classes.green)],
    [matches(constProvider.PATIENT_CATEGORY.BLUE), constant(classes.blue)],
    [matches(constProvider.PATIENT_CATEGORY.UNKNOWN), constant(classes.unknown)]
  ]);

  const { label } = classes;

  const text = get(constProvider.PATIENT_COLOR_TO_CATEGORY, category);

  return (
    <Typography
      component={component}
      align="center"
      className={classnames([className, label, getValueStyle(category)])}
    >
      {text}
    </Typography>
  );
}
