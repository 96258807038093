import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'style';

export const useClasses = makeStyles({
  appFrame: {
    minHeight: '100vh',
    marginTop: 0,
    background: colors.lightGray
  },
  content: {
    paddingTop: 0
  }
});
