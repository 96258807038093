import React, { useCallback, useMemo, useState } from 'react';
import { cond, matches, constant } from 'lodash';
import { Datagrid, DateField, TextField, ReferenceField, useDataProvider, useRefresh } from 'react-admin';
import { constProvider } from 'providers';
import { listCardConsts } from './list-card.const';
import { MeasurementDetailTypeEnum, Measurement } from '../index';
import { FiltersButton, MeasurementField, SwitchField } from '../../../common';
import { DeviceField } from '../../measurement/device-field';
import { ListCardFilters } from './list-card-filters';
import { colors } from '../../../../style';
import { List } from 'components/common';
import { DoctorDescriptionField } from './doctor-description-field/doctor-description-field';
import { ListCardAside } from './list-card-aside/list-card-aside';

type ListCardProps = {
  patientId: string;
  measurementType: MeasurementDetailTypeEnum;
};

export function ListCard({ patientId, measurementType }: ListCardProps) {
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = useCallback(() => setShowFilters(!showFilters), [showFilters]);
  const mainFilters = useMemo(() => ({ patientId, measurementType }), [patientId, measurementType]);
  const { title } = getSettings(measurementType);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [isMeasurementSaving, setIsMeasurementSaving] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Measurement | null>(null);
  const [isImportantSavingForMeasurement, setIsImportantSavingForMeasurement] = useState<Measurement | null>(null);

  const changePatientDetails = useCallback(
    (record: Measurement, checked: boolean, description: string | null) => {
      return dataProvider
        .putCustomUrl(`patient/${patientId}/measurement/${record.id}/details`, {
          data: { important: Boolean(checked), description: description || '' }
        })
        .then(() => {
          refresh();
        });
    },
    [dataProvider, refresh, patientId]
  );

  const handleIsImportant = useCallback(
    (record: Measurement, checked: boolean) => {
      setIsImportantSavingForMeasurement(record);
      changePatientDetails(record, checked, record.description).finally(() => {
        setIsImportantSavingForMeasurement(null);
      });
    },
    [changePatientDetails]
  );

  const onAsideClose = useCallback(() => {
    setSelectedRecord(null);
  }, []);

  const onAsideSave = useCallback(
    (record: Measurement, description: string) => {
      setIsMeasurementSaving(true);
      changePatientDetails(record, record.isImportant, description).finally(() => {
        setIsMeasurementSaving(false);
        onAsideClose();
      });
    },
    [changePatientDetails, onAsideClose]
  );

  const handleClickDescription = useCallback(
    (record: Measurement) => {
      setSelectedRecord(record);
    },
    [setSelectedRecord]
  );

  const showLoadingForIsImportantSaving = useCallback(
    (currentRecord: Measurement) => {
      return Boolean(isImportantSavingForMeasurement && isImportantSavingForMeasurement.id === currentRecord.id);
    },
    [isImportantSavingForMeasurement]
  );

  return (
    <List
      filters={showFilters ? <ListCardFilters /> : undefined}
      title={title}
      bulkActionButtons={false}
      exporter={false}
      actions={<FiltersButton toggleFilters={toggleFilters} />}
      filter={mainFilters}
      basePath={'/'}
      resource={constProvider.RESOURCES.MEASUREMENTS_DETAILS.URI}
      hasCreate={false}
      hasEdit={false}
      hasShow={false}
      hasList={true}
      aside={
        selectedRecord ? (
          <ListCardAside
            measurement={selectedRecord}
            measurementType={measurementType}
            onClose={onAsideClose}
            onSave={onAsideSave}
            isMeasurementSaving={isMeasurementSaving}
          />
        ) : undefined
      }
    >
      <Datagrid rowClick={undefined} rowStyle={getRowStyle}>
        <DateField showTime={true} source="creationDate" label={listCardConsts.FIELDS.CREATION_DATE} />
        <MeasurementField source="value" measurementType={measurementType} label={listCardConsts.FIELDS.VALUE} />
        <DeviceField source="deviceId" label={listCardConsts.FIELDS.DEVICE} />
        <DoctorDescriptionField
          onClick={handleClickDescription}
          source="description"
          label={listCardConsts.FIELDS.DESCRIPTION}
        />
        <ReferenceField
          source="userId"
          reference={constProvider.RESOURCES.USER.URI}
          label={listCardConsts.FIELDS.DOCTOR}
        >
          <TextField />
        </ReferenceField>
        <DateField showTime={true} source="descriptionDate" label={listCardConsts.FIELDS.DESCRIPTION_DATE} />
        <SwitchField<Measurement>
          source="isImportant"
          onChange={handleIsImportant}
          showLoading={showLoadingForIsImportantSaving}
          label={listCardConsts.FIELDS.IS_IMPORTANT}
        />
        {/*<SwitchField<Measurement> source="isValid" onChange={handleIsValid} label={listCardConsts.FIELDS.IS_VALID} />*/}
      </Datagrid>
    </List>
  );
}

function getSettings(measurementType: MeasurementDetailTypeEnum) {
  const { PAGE_TITLE } = listCardConsts;

  return cond([
    [matches(MeasurementDetailTypeEnum.PULSE), constant({ title: PAGE_TITLE.PULSE })],
    [matches(MeasurementDetailTypeEnum.SATURATION), constant({ title: PAGE_TITLE.SATURATION })],
    [matches(MeasurementDetailTypeEnum.TEMPERATURE), constant({ title: PAGE_TITLE.TEMPERATURE })]
  ])(measurementType);
}

function getRowStyle(record: Measurement) {
  return cond([
    [matches('YELLOW'), backgroundColorYellow],
    [matches('RED'), backgroundColorRed],
    [matches('BLUE'), backgroundColorBlue],
    [matches('GREEN'), backgroundColorGreen],
    [() => true, backgroundColorNone]
  ])(record.status);
}

const backgroundColorYellow = constant({ backgroundColor: colors.listYellow });
const backgroundColorGreen = constant({ backgroundColor: colors.listGreen });
const backgroundColorBlue = constant({ backgroundColor: colors.listBlue });
const backgroundColorRed = constant({ backgroundColor: colors.listRed });
const backgroundColorNone = constant({});
