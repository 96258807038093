import { makeStyles } from '@material-ui/core';
import { fontWeight } from 'style';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: ' 14px 30px 30px'
  },
  select: {
    width: '100%'
  },
  sectionHeader: {
    color: theme.palette.text.secondary,
    fontWeight: fontWeight.medium
  }
}));
