import { constProvider } from '../../../providers';

export const userConsts = Object.freeze({
  PAGE_TITLE: `resources.${constProvider.RESOURCES.USER.URI}.name`,
  LIST_TITLE: `resources.${constProvider.RESOURCES.USER.URI}.list.title`,
  CREATE_TITLE: `resources.${constProvider.RESOURCES.USER.URI}.create.title`,
  EDIT_TITLE: `resources.${constProvider.RESOURCES.USER.URI}.edit.title`,
  TABS: {
    LABEL: `resources.${constProvider.RESOURCES.USER.URI}.edit.tabs.label`,
    DETAILS: `resources.${constProvider.RESOURCES.USER.URI}.edit.tabs.details`,
    PERMISSION: `resources.${constProvider.RESOURCES.USER.URI}.edit.tabs.permission`
  },
  USER_ROLES: {
    ADMIN: `resources.${constProvider.RESOURCES.USER.URI}.consts.userRoles.${constProvider.USER_ROLES.ADMIN}`,
    NURSE: `resources.${constProvider.RESOURCES.USER.URI}.consts.userRoles.${constProvider.USER_ROLES.NURSE}`,
    PARAMEDIC: `resources.${constProvider.RESOURCES.USER.URI}.consts.userRoles.${constProvider.USER_ROLES.PARAMEDIC}`,
    DOCTOR: `resources.${constProvider.RESOURCES.USER.URI}.consts.userRoles.${constProvider.USER_ROLES.DOCTOR}`
  }
});

export const userDictionary = Object.freeze({
  USER_ROLES: [
    {
      id: constProvider.USER_ROLES.ADMIN,
      name: `resources.${constProvider.RESOURCES.USER.URI}.consts.userRoles.${constProvider.USER_ROLES.ADMIN}`
    },
    {
      id: constProvider.USER_ROLES.NURSE,
      name: `resources.${constProvider.RESOURCES.USER.URI}.consts.userRoles.${constProvider.USER_ROLES.NURSE}`
    },
    {
      id: constProvider.USER_ROLES.PARAMEDIC,
      name: `resources.${constProvider.RESOURCES.USER.URI}.consts.userRoles.${constProvider.USER_ROLES.PARAMEDIC}`
    },
    {
      id: constProvider.USER_ROLES.DOCTOR,
      name: `resources.${constProvider.RESOURCES.USER.URI}.consts.userRoles.${constProvider.USER_ROLES.DOCTOR}`
    }
  ],
  USER_PRIVILEGES: {
    SECTIONS: [
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.sectionsPrivileges.${constProvider.SECTIONS_PRIVILEGES.USERS}`,
        values: [
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.USERS}_${constProvider.PRIVILEGES.LIST_VIEW}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.USERS}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.USERS}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.sectionsPrivileges.${constProvider.SECTIONS_PRIVILEGES.PATIENTS}`,
        values: [
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.PATIENTS}_${constProvider.PRIVILEGES.LIST_VIEW}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.PATIENTS}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.PATIENTS}_${constProvider.PRIVILEGES.EDIT}`
          },
          {
            name: ''
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.sectionsPrivileges.${constProvider.SECTIONS_PRIVILEGES.RESULTS}`,
        values: [
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.RESULTS}_${constProvider.PRIVILEGES.LIST_VIEW}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.RESULTS}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: ''
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.sectionsPrivileges.${constProvider.SECTIONS_PRIVILEGES.DEVICES}`,
        values: [
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.DEVICES}_${constProvider.PRIVILEGES.LIST_VIEW}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.DEVICES}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.DEVICES}_${constProvider.PRIVILEGES.EDIT}`
          },
          {
            name: ''
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.sectionsPrivileges.${constProvider.SECTIONS_PRIVILEGES.MEASUREMENTS_DEVICE}`,
        values: [
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.MEASUREMENTS_DEVICE}_${constProvider.PRIVILEGES.LIST_VIEW}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.MEASUREMENTS_DEVICE}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.MEASUREMENTS_DEVICE}_${constProvider.PRIVILEGES.EDIT}`
          },
          {
            name: ''
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.sectionsPrivileges.${constProvider.SECTIONS_PRIVILEGES.MEDICAL_CENTERS}`,
        values: [
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.MEDICAL_CENTERS}_${constProvider.PRIVILEGES.LIST_VIEW}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.MEDICAL_CENTERS}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.MEDICAL_CENTERS}_${constProvider.PRIVILEGES.EDIT}`
          },
          {
            name: ''
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.sectionsPrivileges.${constProvider.SECTIONS_PRIVILEGES.REPORTS}`,
        values: [
          {
            name: `${constProvider.SECTIONS_PRIVILEGES.REPORTS}_${constProvider.PRIVILEGES.LIST_VIEW}`
          },
          {
            name: ''
          },
          {
            name: ''
          },
          {
            name: ''
          }
        ]
      }
    ],
    DASHBOARDS: [
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.dashboardsPrivileges.${constProvider.DASHBOARDS_PRIVILEGES.ADMIN_DASHBOARD}`,
        values: [
          {
            name: `${constProvider.DASHBOARDS_PRIVILEGES.ADMIN_DASHBOARD}_${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}`
          },
          {
            name: `${constProvider.DASHBOARDS_PRIVILEGES.ADMIN_DASHBOARD}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.dashboardsPrivileges.${constProvider.DASHBOARDS_PRIVILEGES.PATIENT_DASHBOARD}`,
        values: [
          {
            name: `${constProvider.DASHBOARDS_PRIVILEGES.PATIENT_DASHBOARD}_${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}`
          },
          {
            name: `${constProvider.DASHBOARDS_PRIVILEGES.PATIENT_DASHBOARD}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.dashboardsPrivileges.${constProvider.DASHBOARDS_PRIVILEGES.DOCTORS_DASHBOARD}`,
        values: [
          {
            name: `${constProvider.DASHBOARDS_PRIVILEGES.DOCTORS_DASHBOARD}_${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}`
          },
          {
            name: `${constProvider.DASHBOARDS_PRIVILEGES.DOCTORS_DASHBOARD}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.dashboardsPrivileges.${constProvider.DASHBOARDS_PRIVILEGES.PARAMEDIC_DASHBOARD}`,
        values: [
          {
            name: `${constProvider.DASHBOARDS_PRIVILEGES.PARAMEDIC_DASHBOARD}_${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}`
          },
          {
            name: `${constProvider.DASHBOARDS_PRIVILEGES.PARAMEDIC_DASHBOARD}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      }
    ],
    SPECIFIC: [
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.specificPrivileges.${constProvider.SPECIFIC_PRIVILEGES.DEVICE_ASSIGNEMENT}`,
        values: [
          {
            name: ''
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.DEVICE_ASSIGNEMENT}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.DEVICE_ASSIGNEMENT}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.specificPrivileges.${constProvider.SPECIFIC_PRIVILEGES.DEFINE_GEOLOCATION_AREA}`,
        values: [
          {
            name: ''
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.DEFINE_GEOLOCATION_AREA}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.DEFINE_GEOLOCATION_AREA}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.specificPrivileges.${constProvider.SPECIFIC_PRIVILEGES.DEFINE_SMS_NOTIFICATION}`,
        values: [
          {
            name: ''
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.DEFINE_SMS_NOTIFICATION}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.DEFINE_SMS_NOTIFICATION}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.specificPrivileges.${constProvider.SPECIFIC_PRIVILEGES.IMPORT_EXPORT_SCP_FILES}`,
        values: [
          {
            name: ''
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.IMPORT_EXPORT_SCP_FILES}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.IMPORT_EXPORT_SCP_FILES}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.specificPrivileges.${constProvider.SPECIFIC_PRIVILEGES.PATIENT_CARD}`,
        values: [
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.PATIENT_CARD}_${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}`
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.PATIENT_CARD}_${constProvider.PRIVILEGES.ADD}`
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.PATIENT_CARD}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.specificPrivileges.${constProvider.SPECIFIC_PRIVILEGES.DEVICE_PROGRAMMING}`,
        values: [
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.DEVICE_PROGRAMMING}_${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}`
          },
          {
            name: ''
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.DEVICE_PROGRAMMING}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      },
      {
        privilegeName: `resources.${constProvider.RESOURCES.USER.URI}.consts.specificPrivileges.${constProvider.SPECIFIC_PRIVILEGES.FIRMWARE_UPDATE}`,
        values: [
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.FIRMWARE_UPDATE}_${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}`
          },
          {
            name: ''
          },
          {
            name: `${constProvider.SPECIFIC_PRIVILEGES.FIRMWARE_UPDATE}_${constProvider.PRIVILEGES.EDIT}`
          }
        ]
      }
    ]
  },
  PRIVILEGES: [
    {
      name: `resources.${constProvider.RESOURCES.USER.URI}.consts.privileges.${constProvider.PRIVILEGES.LIST_VIEW}`
    },
    {
      name: `resources.${constProvider.RESOURCES.USER.URI}.consts.privileges.${constProvider.PRIVILEGES.READ_ONLY_PROPERTIES}`
    },
    {
      name: `resources.${constProvider.RESOURCES.USER.URI}.consts.privileges.${constProvider.PRIVILEGES.ADD}`
    },
    {
      name: `resources.${constProvider.RESOURCES.USER.URI}.consts.privileges.${constProvider.PRIVILEGES.EDIT}`
    }
  ]
});

export type UserPermissions =
  | 'NORMAL_USER'
  | 'ROLE_SUPER_ADMIN'
  | 'SECTION_USERS_LIST_VIEW'
  | 'SECTION_USERS_READ_ONLY_PROPERTIES'
  | 'SECTION_USERS_ADD'
  | 'SECTION_USERS_EDIT'
  | 'SECTION_USERS_DELETE'
  | 'SECTION_PATIENTS_LIST_VIEW'
  | 'SECTION_PATIENTS_READ_ONLY_PROPERTIES'
  | 'SECTION_PATIENTS_ADD'
  | 'SECTION_PATIENTS_EDIT'
  | 'SECTION_RESULTS_LIST_VIEW'
  | 'SECTION_RESULTS_READ_ONLY_PROPERTIES'
  | 'SECTION_RESULTS_ADD'
  | 'SECTION_RESULTS_DELETE'
  | 'SECTION_DEVICES_LIST_VIEW'
  | 'SECTION_DEVICES_READ_ONLY_PROPERTIES'
  | 'SECTION_DEVICES_ADD'
  | 'SECTION_DEVICES_EDIT'
  | 'SECTION_MEASUREMENTS_DEVICE_LIST_VIEW'
  | 'SECTION_MEASUREMENTS_DEVICE_READ_ONLY_PROPERTIES'
  | 'SECTION_MEASUREMENTS_DEVICE_ADD'
  | 'SECTION_MEASUREMENTS_DEVICE_EDIT'
  | 'SECTION_MEDICAL_CENTERS_LIST_VIEW'
  | 'SECTION_MEDICAL_CENTERS_READ_ONLY_PROPERTIES'
  | 'SECTION_MEDICAL_CENTERS_ADD'
  | 'SECTION_MEDICAL_CENTERS_EDIT'
  | 'SECTION_REPORTS_LIST_VIEW'
  | 'SECTION_REPORTS_READ_ONLY_PROPERTIES'
  | 'DASHBOARDS_ADMIN_DASHBOARD_READ_ONLY_PROPERTIES'
  | 'DASHBOARDS_ADMIN_DASHBOARD_EDIT'
  | 'DASHBOARDS_PATIENT_DASHBOARD_READ_ONLY_PROPERTIES'
  | 'DASHBOARDS_PATIENT_DASHBOARD_EDIT'
  | 'DASHBOARDS_DOCTORS_DASHBOARD_READ_ONLY_PROPERTIES'
  | 'DASHBOARDS_DOCTORS_DASHBOARD_EDIT'
  | 'DASHBOARDS_PARAMEDIC_DASHBOARD_READ_ONLY_PROPERTIES'
  | 'DASHBOARDS_PARAMEDIC_DASHBOARD_EDIT'
  | 'SPECIFIC_DEVICE_ASSIGNMENT_ADD'
  | 'SPECIFIC_DEVICE_ASSIGNMENT_EDIT'
  | 'SPECIFIC_DEFINE_GEOLOCATION_AREA_ADD'
  | 'SPECIFIC_DEFINE_GEOLOCATION_AREA_EDIT'
  | 'SPECIFIC_DEFINE_SMS_NOTIFICATION_ADD'
  | 'SPECIFIC_DEFINE_SMS_NOTIFICATION_EDIT'
  | 'SPECIFIC_IMPORT_EXPORT_SCP_FILES_ADD'
  | 'SPECIFIC_IMPORT_EXPORT_SCP_FILES_EDIT'
  | 'SPECIFIC_PATIENT_CARD_READ_ONLY_PROPERTIES'
  | 'SPECIFIC_PATIENT_CARD_ADD'
  | 'SPECIFIC_PATIENT_CARD_EDIT'
  | 'SPECIFIC_DEVICE_PROGRAMMING_READ_ONLY_PROPERTIES'
  | 'SPECIFIC_DEVICE_PROGRAMMING_EDIT'
  | 'SPECIFIC_FIRMWARE_UPDATE_READ_ONLY_PROPERTIES'
  | 'SPECIFIC_FIRMWARE_UPDATE_EDIT';
