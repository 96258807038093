import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, AppBar, Typography, CircularProgress } from '@material-ui/core';
import { Measurement, MeasurementDetailTypeEnum } from '../../index';
import { colors } from 'style';
import { useTranslate, required, TextInput } from 'react-admin';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { DateFieldWithIcon } from 'components/common/DateFieldWithIcon';
import { formatMeasurement } from 'services/utils';

interface ListCardAsideProps {
  measurement: Measurement;
  onClose: () => void;
  onSave: (record: Measurement, description: string) => void;
  measurementType: MeasurementDetailTypeEnum;
  isMeasurementSaving: boolean;
}

interface FormModel {
  description: string;
}

export function ListCardAside({
  isMeasurementSaving,
  measurementType,
  onClose,
  onSave,
  measurement
}: ListCardAsideProps) {
  const classes = useStyles();
  const translate = useTranslate();
  const { value, creationDate } = measurement;

  const handleSubmit = useCallback(
    ({ description }: FormModel) => {
      if (!isMeasurementSaving) {
        onSave(measurement, description);
      }
    },
    [measurement, onSave, isMeasurementSaving]
  );

  const handleCloseClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  const handleCloseBackground = useCallback(
    (e: React.MouseEvent) => {
      if (e.target === e.currentTarget) {
        handleCloseClick(e);
      }
    },
    [handleCloseClick]
  );

  const renderForm = useCallback(
    (props: FormRenderProps<FormModel>) => {
      return (
        <form onSubmit={props.handleSubmit} className={classes.contentContainer}>
          <Box className={classes.formContent}>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <span className={classes.label}>
                  {translate('resources.patient.edit.dashboard.measurement.aside.labels.examination')}
                </span>{' '}
                <span className={classes.value}>
                  {translate(`resources.patient.edit.dashboard.measurement.aside.labels.${measurementType}`)}
                </span>
              </li>
              <li className={classes.listItem}>
                <span className={classes.label}>
                  {translate('resources.patient.edit.dashboard.measurement.aside.labels.result')}
                </span>{' '}
                <span className={classes.value}>{formatMeasurement(value, measurementType)}</span>
              </li>
              <li className={classes.listItem}>
                <DateFieldWithIcon date={creationDate} />
              </li>
              <Field<FormModel['description']>
                name="description"
                validate={required()}
                component={TextInput}
                multiline
                disabled={isMeasurementSaving}
                label={translate('resources.patient.edit.dashboard.measurement.aside.labels.description')}
              />
            </ul>
          </Box>
          <Box className={classes.buttons}>
            <Button color="primary" onClick={handleCloseClick}>
              {translate('resources.patient.edit.dashboard.measurement.aside.buttons.cancel')}
            </Button>
            {}
            {isMeasurementSaving ? (
              <Box className={classes.loadingButton}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Button variant="contained" color="primary" type="submit" disabled={isMeasurementSaving}>
                {translate('resources.patient.edit.dashboard.measurement.aside.buttons.save')}
              </Button>
            )}
          </Box>
        </form>
      );
    },
    [
      classes.contentContainer,
      classes.formContent,
      classes.buttons,
      classes.list,
      classes.listItem,
      classes.label,
      classes.value,
      classes.loadingButton,
      handleCloseClick,
      translate,
      creationDate,
      measurementType,
      value,
      isMeasurementSaving
    ]
  );

  return (
    <div className={classes.container} onClick={handleCloseBackground}>
      <Box className={classes.aside}>
        <AppBar position="static" className={classes.appBar}>
          <Typography variant="h6">{translate('resources.patient.edit.dashboard.measurement.aside.title')}</Typography>
        </AppBar>
        <Form onSubmit={handleSubmit} render={renderForm} />
      </Box>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'fixed',
    background: colors.black54,
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  aside: {
    width: '250px',
    display: 'flex',
    flexDirection: 'column'
  },
  appBar: {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0 22px'
  },
  contentContainer: {
    background: colors.white,
    flexGrow: 1,
    padding: '20px 22px 37px 22px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch'
  },
  formContent: {
    flexGrow: 1
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  listItem: {
    listStyle: 'none',
    marginBottom: '4px'
  },
  label: {
    fontSize: '16px',
    color: colors.black54,
    fontWeight: 500
  },
  value: {
    fontSize: '16px',
    color: colors.black87,
    fontWeight: 500
  },
  loadingButton: {
    marginRight: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
