import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { constProvider } from 'providers';
import { Timer } from '@material-ui/icons';
import classNames from 'classnames';
interface DateFieldWithIconProps {
  readonly date: string | Moment | undefined | null;
  readonly className?: string;
}

export function DateFieldWithIcon({ date, className }: DateFieldWithIconProps) {
  const classes = useStyles();

  return (
    <Box className={classNames([classes.iconContainer, className])}>
      <Timer className={classes.timerIcon} />
      <Typography variant="subtitle2">{date ? moment(date).format(constProvider.DATE_TIME_FORMAT) : '-'}</Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  timerIcon: {
    width: '18px',
    height: '18px',
    marginRight: '8px'
  }
}));
