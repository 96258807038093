export const basicInfoConsts = Object.freeze({
  NAME: 'resources.patient.edit.dashboard.name',
  SURNAME: 'resources.patient.edit.dashboard.surname',
  LABEL: 'resources.patient.edit.dashboard.basicPatientInfo',
  GENDER: 'resources.patient.edit.dashboard.gender',
  PHONE_NUMBER: 'resources.patient.edit.dashboard.phoneNumber',
  IDENTIFIER: 'resources.patient.edit.dashboard.identifier',
  MEDICAL_CENTER_NAME: 'resources.patient.edit.dashboard.medicalCenterName',
  CATEGORY: 'resources.patient.edit.dashboard.category',
  AGE: 'resources.patient.edit.dashboard.age',
  DEVICE: 'resources.patient.edit.dashboard.device'
});
