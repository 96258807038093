import { makeStyles } from '@material-ui/core/styles';
import { fontWeight, colors } from 'style';

export const useStyles = makeStyles((theme) => ({
  content: {
    padding: '16px 18px',
    '&:last-child': {
      paddingBottom: '10px'
    }
  },
  save: {
    float: 'right',
    clear: 'both'
  },
  header: {
    marginBottom: '12px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500' as 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: colors.black54
  },
  prop: {
    color: theme.palette.text.primary,
    fontWeight: fontWeight.medium,
    marginRight: '5px'
  },
  text: {
    color: theme.palette.text.secondary
  },
  label: {
    color: 'white',
    width: '112px',
    height: '23px',
    borderRadius: '3px',
    fontSize: '14px',
    fontWeight: fontWeight.medium
  }
}));
