import { AddBox, MoreVert, Timer } from '@material-ui/icons';
import { Box, Card, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { PatientDetailsMeasurementDto, PatientMeasurementDto } from 'models/models';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDataProvider, useNotify, useTranslate, LinearProgress } from 'react-admin';

import { constProvider } from 'providers';
import { get } from 'lodash';
import moment from 'moment';
import { patientDashboardConsts } from '../patient-dashboard.const';
import { useStyles } from './status-card.styles';

type CardProps = {
  data: PatientMeasurementDto;
  refreshData: () => void;
};

export function StatusCard({ data, refreshData }: CardProps) {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const [userData, setUserData] = useState<PatientDetailsMeasurementDto>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setUserData(data.patient);
    setLoading(false);
  }, [data]);

  const onOpen = (e: MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusChange = useCallback(
    (e: MouseEvent, status: string) => {
      e.stopPropagation();
      handleClose();
      setLoading(true);
      const { id } = data;

      if (id) {
        return dataProvider
          .patch(constProvider.RESOURCES.PATIENT.URI, {
            id: `${id}/status`,
            data: {
              status
            }
          })
          .then(refreshData)
          .catch((error: Error) => {
            notify(error.message, 'warning');
            setLoading(false);
          });
      }
    },
    [dataProvider, data, notify, refreshData]
  );

  if (loading)
    return (
      <Card className={classes.container}>
        <LinearProgress />
      </Card>
    );

  if (!userData) return null;

  const { patientStatus, statusChangeDate, statusChangeOwner } = userData;

  return (
    <Card className={classes.container}>
      <Box>
        <Typography variant="h6">{translate(patientDashboardConsts.STATUS.STATUS)}:</Typography>
      </Box>
      <Box mx={3}>
        <Typography component="span">{translate(get(constProvider.PATIENT_STATUS_TO_LABEL, patientStatus))}</Typography>
      </Box>
      {statusChangeDate && (
        <Box display="flex" alignItems="center">
          <Timer className={classes.icon} />
          <Typography variant="subtitle2">{moment(statusChangeDate).format(constProvider.DATE_TIME_FORMAT)}</Typography>
        </Box>
      )}
      {statusChangeOwner && (
        <Box display="flex" alignItems="center" mx={3}>
          <AddBox className={classes.icon} /> <Typography variant="subtitle2">{statusChangeOwner}</Typography>
        </Box>
      )}
      <Box className={classes.dropdown}>
        <IconButton aria-label="more" aria-controls="more-menu" aria-haspopup="true" onClick={onOpen}>
          <MoreVert />
        </IconButton>
        <Menu id="more-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          {Object.entries(constProvider.PATIENT_STATUS_TO_LABEL)
            .filter(([id]) => id !== 'NEWLY_REGISTERED')
            .map(([id, name]) => (
              <MenuItem key={id} onClick={(e) => statusChange(e, id)}>
                {translate(name)}
              </MenuItem>
            ))}
        </Menu>
      </Box>
    </Card>
  );
}
