import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  useDataProvider,
  useTranslate,
  useMutation,
  Loading,
  SaveButton,
  useNotify,
  TextInput,
  BooleanInput,
  NumberInput,
  required,
  number,
  minValue,
  maxValue
} from 'react-admin';
import { Grid, Box, Toolbar, Typography } from '@material-ui/core';
import { deviceConsts } from '../../Device.const';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { constProvider } from 'providers';
import { sortBy, cond } from 'lodash';
import { composeValidators } from 'services/validators';
import { range, constant } from 'lodash';
import { useStyles } from './device-programming.styles';
import {
  DeviceProgrammingProps,
  DeviceProgrammingValues,
  DeviceProgrammingConfigElement,
  DeviceProgrammingResponse,
  ConfigurationSectionName
} from './device-programming.types';

function getDeviceProgrammingConfigElements(
  section: string,
  formConfiguration: Map<ConfigurationSectionName, DeviceProgrammingConfigElement[]>
) {
  return formConfiguration.get(section) || [];
}

export function DeviceProgramming({ record }: DeviceProgrammingProps) {
  const deviceId = record.id;
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const [formInitialValues, setFormInitialValues] = useState<object | null>(null);
  const [formConfiguration, setFormConfiguration] = useState<
    Map<ConfigurationSectionName, DeviceProgrammingConfigElement[]>
  >(new Map());

  const [mutate] = useMutation();

  useEffect(() => {
    dataProvider
      .getCustomUrl(`${constProvider.RESOURCES.DEVICE_PROGRAMMING.URI}/${deviceId}`)
      .then(({ data: { data } }: DeviceProgrammingResponse) => {
        setFormConfiguration(parseResponseDataToFormConfiguration(data));
        setFormInitialValues(parseResponseDataToFormInitialValues(data));
      });
  }, [dataProvider, deviceId]);

  const handleSubmit = useCallback(
    (formData: DeviceProgrammingValues) => {
      mutate(
        {
          type: 'create',
          resource: constProvider.RESOURCES.DEVICE_PROGRAMMING.URI,
          payload: {
            id: deviceId,
            data: { deviceId: deviceId, data: parseFormDataToSubmissionFormat(formData, formConfiguration) }
          }
        },
        {
          onSuccess: () => {
            notify(deviceConsts.PROGRAMMING_NOTIFICATIONS.SUCCESS, 'success', {
              smart_count: 1
            });
          },
          onFailure: () => {
            notify(deviceConsts.PROGRAMMING_NOTIFICATIONS.FAILURE, 'failure', {
              smart_count: 1
            });
          }
        }
      );
    },
    [deviceId, mutate, formConfiguration, notify]
  );

  const configurationSections = useMemo(() => [...formConfiguration.keys()], [formConfiguration]);

  const renderForm = useCallback(
    (formProps: FormRenderProps<DeviceProgrammingValues>) => {
      return (
        <form>
          <Box className={classes.container}>
            <Typography variant="subtitle1" className={classes.sectionHeader}>
              {translate(deviceConsts.COMMANDS_LABELS.TITLE)}
            </Typography>
          </Box>

          {configurationSections
            .filter((section) => getDeviceProgrammingConfigElements(section, formConfiguration).length > 0)
            .map((section) => (
              <Box key={section} className={classes.container}>
                <Typography variant="subtitle1" className={classes.sectionHeader}>
                  {section}
                </Typography>
                <Grid container item xs={12} spacing={2}>
                  {getDeviceProgrammingConfigElements(section, formConfiguration).map((data) => (
                    <Grid item xs={4} key={data.id}>
                      {getField(data, translate)}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}

          <Toolbar>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <SaveButton
                label={deviceConsts.COMMANDS_LABELS.SEND_BUTTON}
                saving={formProps.submitting}
                handleSubmitWithRedirect={formProps.handleSubmit}
              />
            </Box>
          </Toolbar>
        </form>
      );
    },
    [formConfiguration, configurationSections, classes.container, classes.sectionHeader, translate]
  );

  if (null === formConfiguration || null === formInitialValues) {
    return <Loading />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={formInitialValues}
      subscription={defaultSubscription}
      keepDirtyOnReinitialize
      render={renderForm}
    />
  );
}

function parseResponseDataToFormConfiguration(data: DeviceProgrammingConfigElement[]) {
  const formConfiguration = new Map<ConfigurationSectionName, DeviceProgrammingConfigElement[]>();

  data.forEach((config) => {
    const { configurationSectionName } = config;

    const currentCollectionValue = formConfiguration.get(configurationSectionName) || [];
    const newCollectionValue = sortBy([...currentCollectionValue, config], 'order');

    formConfiguration.set(configurationSectionName, newCollectionValue);
  });
  return formConfiguration;
}

function parseResponseDataToFormInitialValues(data: DeviceProgrammingConfigElement[]) {
  return data.reduce((acc: any, { id, currentValue, rangeMin, rangeMax, precision }) => {
    acc[getFieldName(id)] = isBooleanValue(rangeMin, rangeMax, precision) ? Boolean(currentValue) : currentValue;
    return acc;
  }, {} as any);
}

function parseFormDataToSubmissionFormat(
  formData: DeviceProgrammingValues,
  formConfiguration: Map<ConfigurationSectionName, DeviceProgrammingConfigElement[]>
): DeviceProgrammingConfigElement[] {
  return [...formConfiguration.values()]
    .reduce((acc, config) => [...acc, ...config], [])
    .map((element) => ({ ...element, currentValue: formData[getFieldName(element.id)] }))
    .map((element) => ({ ...element, currentValue: element.currentValue === true ? 1 : element.currentValue }))
    .map((element) => ({ ...element, currentValue: element.currentValue === false ? 0 : element.currentValue }));
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
};

function getField(config: DeviceProgrammingConfigElement, translate: (text: string, values: any) => string): any {
  return cond<DeviceProgrammingConfigElement, any>([
    [({ rangeMin, rangeMax, precision }) => isBooleanValue(rangeMin, rangeMax, precision), getBooleanField(config)],
    [
      ({ rangeMin, rangeMax, precision }) => rangeMax !== null && rangeMin !== null && precision !== null,
      getNumericalField(config, translate)
    ],
    [() => true, getTextField(config)]
  ])(config);

  function getBooleanField(config: DeviceProgrammingConfigElement) {
    return constant(<BooleanInput fullWidth={true} label={config.name} source={getFieldName(config.id)} />);
  }

  function getNumericalField(
    config: DeviceProgrammingConfigElement,
    translate: (text: string, options: any) => string
  ) {
    return constant(
      <Field
        fullWidth={true}
        name={getFieldName(config.id)}
        label={config.name}
        component={NumberInput}
        min={config.rangeMin}
        max={config.rangeMax}
        step={getPrecision(config.precision)}
        type={'number'}
        resource={getFieldName(config.id)}
        validate={composeValidators(required(), number(), minValue(config.rangeMin), maxValue(config.rangeMax))}
        parse={formatValueToPrecision(config.precision)}
        helperText={translate(deviceConsts.PROGRAMMING.RANGE_HELPER, {
          minValue: config.rangeMin,
          maxValue: config.rangeMax,
          unit: config.unit || ''
        })}
      />
    );

    function formatValueToPrecision(precision: number) {
      return (value: number | string) => Number(value).toFixed(precision);
    }

    function getPrecision(precision: number): number {
      return precision === 0 ? 1 : Number(`0.${range(0, precision - 1, 0).join('')}1`);
    }
  }

  function getTextField(config: DeviceProgrammingConfigElement) {
    return constant(
      <Field
        fullWidth={true}
        name={getFieldName(config.id)}
        label={config.name}
        component={TextInput}
        validate={required()}
      />
    );
  }
}

function getFieldName(id: string | number): string {
  return `field_${id.toString()}`;
}

function isBooleanValue(rangeMin: number | null, rangeMax: number | null, precision: number | null): boolean {
  return rangeMin === 0 && rangeMax === 1 && precision === 0;
}
