export const patientInfoConsts = Object.freeze({
  LABEL: 'resources.patient.edit.dashboard.basicPatientInfo',
  GENDER: 'resources.patient.edit.dashboard.gender',
  PHONE_NUMBER: 'resources.patient.edit.dashboard.phoneNumber',
  IDENTIFIER: 'resources.patient.edit.dashboard.identifier',
  MEDICAL_CENTER_NAME: 'resources.patient.edit.dashboard.medicalCenterName',
  CATEGORY: 'resources.patient.edit.dashboard.category',

  WEIGHT: 'resources.patient.edit.card.weight',
  AGE: 'resources.patient.edit.card.age'
});
