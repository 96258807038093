export const listCardConsts = Object.freeze({
  PAGE_TITLE: {
    PULSE: 'resources.patient.edit.dashboard.measurement.tableTitlePulse',
    TEMPERATURE: 'resources.patient.edit.dashboard.measurement.tableTitleTemperature',
    SATURATION: 'resources.patient.edit.dashboard.measurement.tableTitleSaturation'
  },
  FIELDS: {
    CREATION_DATE: 'resources.patient.edit.dashboard.measurement.filters.creationDate',
    DESCRIPTION_DATE: 'resources.patient.edit.dashboard.measurement.filters.descriptionDate',
    IS_IMPORTANT: 'resources.patient.edit.dashboard.measurement.filters.isImportant',
    VALUE: 'resources.patient.edit.dashboard.measurement.filters.value',
    DEVICE: 'resources.patient.edit.dashboard.measurement.filters.device',
    DESCRIPTION: 'resources.patient.edit.dashboard.measurement.filters.description',
    DOCTOR: 'resources.patient.edit.dashboard.measurement.filters.doctor',
    IS_VALID: 'resources.patient.edit.dashboard.measurement.filters.isValid'
  }
});
