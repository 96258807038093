import React, { useCallback } from 'react';
import { Measurement } from '../../index';
import { FunctionField } from 'react-admin';
import { noop } from 'lodash';
import { Create } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

interface DoctorDescriptionFieldProps {
  onClick?: (record: Measurement) => void;
  source: string;
  label?: string;
}

export function DoctorDescriptionField({ onClick = noop, ...props }: DoctorDescriptionFieldProps) {
  const handleClick = useCallback(
    (record: Measurement) => (e: React.MouseEvent) => {
      e.preventDefault();
      onClick(record);
    },
    [onClick]
  );

  const render = useCallback(
    (record: Measurement) =>
      record.description === null || record.description === '' ? (
        <IconButton onClick={handleClick(record)}>
          <Create />
        </IconButton>
      ) : (
        record.description
      ),
    [handleClick]
  );
  return <FunctionField render={render} {...props} />;
}
