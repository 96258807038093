import { Box, Divider, Grid, Modal, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useMemo } from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useTranslate,
  useDataProvider,
  useRefresh
} from 'react-admin';
import { __RouterContext as RouterContext } from 'react-router';
import {
  CreateInstanceAside,
  ModalContent,
  UniversalFilterFields,
  ListPicker,
  ListPickerColumns
} from 'components/common';
import { StatusField } from 'components/common/status-field/status-field';
import { useButtonPositioning, useModalController, useUnlinkButton } from 'hooks/common';
import { Device, MeasuringDeviceDto } from 'models/models';
import { constProvider } from 'providers';
import { defaultListProps } from 'services/utils';
import { EditView } from 'types';
import { deviceConsts } from '../../Device.const';
import { DeviceType } from '../device-type';
import { useStyles } from './device-measuring-device.styles';

const filterFields: UniversalFilterFields<MeasuringDeviceDto> = [
  {
    source: 'serialNumber'
  },
  {
    source: 'addressBDA'
  },
  {
    source: 'type'
  }
];

const listColumns: ListPickerColumns<MeasuringDeviceDto> = [
  {
    source: 'serialNumber'
  },
  {
    source: 'addressBDA'
  },
  {
    source: 'type',
    render(item) {
      return <DeviceType {...item} />;
    }
  }
];

export function DeviceMeasuringDevice(props: EditView<Device>) {
  const { record } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const rowSelectable = useCallback(() => false, []);

  const routerContext = useContext(RouterContext);
  const routerContextOverrides = { ...routerContext, location: { ...routerContext.location, search: '' } };

  const UnlinkButton = useUnlinkButton('DEVICE_MEASURINGDEVICE', { deviceId: record.id });
  const { setContainerNode, style: createInstanceButtonStyle } = useButtonPositioning({ x: 'right', y: 'bottom' });

  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const { isOpen: showAvailable, open: openAvailableModal, close: closeAvailableModal } = useModalController();
  const measuringDevicesAdded = useCallback(
    (ids: number[]) => {
      ids.forEach((id) => {
        dataProvider
          .patch(constProvider.RESOURCES.DEVICE_MEASURINGDEVICE.URI, { id, data: { deviceId: record.id } })
          .then(() => refresh());
      });
      closeAvailableModal();
    },
    [dataProvider, refresh, closeAvailableModal, record.id]
  );

  const listProps = {
    ...defaultListProps,
    basePath: `/${constProvider.RESOURCES.DEVICE.URI}`,
    resource: constProvider.RESOURCES.DEVICE_MEASURINGDEVICE.URI,
    filterDefaultValues: { $deviceId: record.id },
    aside: <CreateInstanceAside style={createInstanceButtonStyle} callback={openAvailableModal} />
  };

  const datagridClasses = useMemo(() => ({ rowCell: classes.row, headerCell: classes.row }), [classes.row]);

  const onRowClick = useCallback(
    (id: string, basePath: string, record: any) => `/${constProvider.RESOURCES.MEASURING_DEVICE.URI}/${record.id}`,
    []
  );

  return (
    <div
      ref={(node) => {
        setContainerNode(node);
        if (!node) {
          return;
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {translate(deviceConsts.MEASURING_DEVICE.LABELS.TITLE)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RouterContext.Provider value={routerContextOverrides}>
            <List {...listProps} on>
              <Datagrid isRowSelectable={rowSelectable} classes={datagridClasses} rowClick={onRowClick}>
                <TextField source="serialNumber" />
                <TextField source="addressBDA" />
                <TextField source="model" />
                <FunctionField source="type" render={DeviceType} />
                <ReferenceField
                  variant="standard"
                  source="medicalCenterId"
                  reference={constProvider.RESOURCES.MEDICAL_CENTER.URI}
                  link={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <StatusField source="status" />
                <FunctionField
                  title={translate(deviceConsts.MEASURING_DEVICE.LABELS.UNLINK)}
                  label={deviceConsts.MEASURING_DEVICE.LABELS.ACTION}
                  render={UnlinkButton}
                />
              </Datagrid>
            </List>
          </RouterContext.Provider>
        </Grid>
      </Grid>
      <Modal open={showAvailable} disableAutoFocus disableEnforceFocus onBackdropClick={closeAvailableModal}>
        <ModalContent
          title={deviceConsts.MEASURING_DEVICE.LABELS.ASSIGN_TITLE}
          className={classes.searchAvailableModal}
        >
          <ListPicker
            onCancel={closeAvailableModal}
            onSave={measuringDevicesAdded}
            basePath={`/${constProvider.RESOURCES.DEVICE.URI}`}
            resource={constProvider.RESOURCES.AVAILABLE_MEASURINGDEVICE.URI}
            filterDefaultValues={{ $deviceId: record.id }}
            filterFields={filterFields}
            listColumns={listColumns}
          >
            <Box>
              <Typography className={classes.sectionTitle} variant="h6" component="p">
                {translate(deviceConsts.MEASURING_DEVICE.LABELS.SERIAL_NUMBER)}: {record.serialNumber}
              </Typography>
              <Typography className={classes.deviceDetails} variant="subtitle2" component="div">
                {translate(deviceConsts.MEASURING_DEVICE.LABELS.TYPE)}:&nbsp;
                <span className={classes.statusValue}>{record.deviceType}</span>
              </Typography>
              <Typography className={classes.deviceDetails} variant="subtitle2" component="div">
                {translate(deviceConsts.MEASURING_DEVICE.LABELS.SIM_NUMBER)}:&nbsp;
                <span className={classes.statusValue}>{record.simNumber}</span>
              </Typography>
              <Divider className={classes.wideDivider} />
              <Typography className={classes.deviceDetails} variant="subtitle1" component="div">
                {translate(deviceConsts.MEASURING_DEVICE.LABELS.DEVICE_CHOICE_SUBTITLE)}
              </Typography>
            </Box>
          </ListPicker>
        </ModalContent>
      </Modal>
    </div>
  );
}
