import { FunctionField } from 'react-admin';

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { LocationOn } from '@material-ui/icons';
import { DoctorPatientDto, PatientLockdownStatusEnum } from 'models/models';
import { constProvider } from 'providers';
import { makeStyles } from '@material-ui/core/styles';

type LocationFieldProps = {
  source: string;
} & any;

const useStyles = makeStyles({
  text: {
    color: 'black'
  }
});

export function LocationField({ source, ...restProps }: LocationFieldProps) {
  const classes = useStyles();

  const renderCategoryField = useCallback(
    (props: DoctorPatientDto) => {
      const locationStatus: PatientLockdownStatusEnum = get(props, source);
      if (!locationStatus) return '-';
      const id = get(props, 'id');

      const iconItem = () => {
        return locationStatus === constProvider.PATIENT_LOCKDOWN_STATUS.IN ? (
          <LocationOn />
        ) : (
          <LocationOn color="error" />
        );
      };

      if (!id) return iconItem();

      return (
        <Link to={`${constProvider.RESOURCES.PATIENT.URI}/${id}/location`} className={classes.text}>
          {iconItem()}
        </Link>
      );
    },
    [source, classes.text]
  );

  return <FunctionField source={source} render={renderCategoryField} {...restProps} />;
}
