import { makeStyles } from '@material-ui/core/styles';
import { fontWeight } from 'style';
import { colors } from 'style';

export const useStyles = makeStyles(() => ({
  container: {
    margin: '12px',
    display: 'flex'
  },
  measurementContent: {
    padding: '32px 18px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  label: {
    // marginTop: '35px'
  },
  measurementValue: {
    margin: '16px 0',
    fontWeight: fontWeight.medium,
    color: colors.darkYellow
  },
  bottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  red: {
    color: colors.darkerRed
  },
  green: { color: colors.lightGreen }
}));
