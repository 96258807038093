import React from 'react';
import { Field } from 'react-final-form';

interface ConditionFieldProps<M> {
  shouldEqual: any;
  children: React.ReactNode;
  fieldValue: string;
}

export function ConditionField<M>({ fieldValue, shouldEqual, children }: ConditionFieldProps<M>) {
  return (
    <Field name={fieldValue} subscription={{ value: true }}>
      {({ input: { value } }: { input: { value: any } }) => (value === shouldEqual ? children : null)}
    </Field>
  );
}
