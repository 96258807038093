import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';
import { PatientMeasurementDto } from 'models/models';
import { constProvider } from 'providers';
import React from 'react';
import { useGetOne, useTranslate } from 'react-admin';
import { usePatientCategoryBadge } from 'hooks/common';
import { patientInfoConsts } from './patient-info-card.const';
import { useStyles } from './patient-info-card.styles';

type PatientInfoCard = {
  className?: string;
  data: PatientMeasurementDto;
  hideTitle?: boolean;
};

export function PatientInfoCard({ className, data }: PatientInfoCard) {
  const translate = useTranslate();
  const classes = useStyles();

  const { id, patient } = data;
  const { gender, medicalCenterId, pesel, age, weight } = patient;

  const patientId = id ?? patient.id;

  const { PatientCategoryBadge } = usePatientCategoryBadge(patientId);

  const { data: medicalCenter } = useGetOne(constProvider.RESOURCES.MEDICAL_CENTER.URI, medicalCenterId);

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className={classes.header}>
              {translate(patientInfoConsts.LABEL)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientInfoConsts.GENDER)}:</span>
              <span className={classes.text}>{translate(get(constProvider.GENDER_TO_LABEL, gender))}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientInfoConsts.AGE)}:</span>
              <span className={classes.text}>{age || 'N/A'}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientInfoConsts.IDENTIFIER)}:</span>
              <span className={classes.text}>{pesel}</span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientInfoConsts.WEIGHT)}:</span>
              <span className={classes.text}>{weight || 'N/A'}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.prop}>{translate(patientInfoConsts.MEDICAL_CENTER_NAME)}:</span>
              <span className={classes.text}>{medicalCenter ? medicalCenter.name : ''}</span>
            </Typography>
            <Typography>
              <span className={classes.prop}>{translate(patientInfoConsts.CATEGORY)}:</span>
              <PatientCategoryBadge />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
