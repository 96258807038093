export const usersFormConsts = Object.freeze({
  DETAILS_SUBTITLE: 'resources.user.userDetails.subtitle',
  MEDICALCENTER_SUBTITLE: 'resources.user.medicalcenter.subtitle',
  ACCOUNT_DETAILS_SUBTITLE: 'resources.user.accountDetails.subtitle',
  SECTIONS_SUBTITLE: 'resources.user.consts.sectionsPrivileges.subtitle',
  DASHBOARDS_SUBTITLE: 'resources.user.consts.dashboardsPrivileges.subtitle',
  LABELS: {
    MEDICAL_CENTER: 'resources.user.medicalcenter.nameLabel'
  },
  VALIDATION: {
    phoneNumber: 'validation.phoneNumberInvalidMessage',
    email: 'validation.emailInvalidMessage',
    passwordValidation: 'validation.passwordValidation',
    required: 'validation.requiredValidation'
  },
  BUTTONS: {
    cancelButtonCaption: 'common.button.cancel',
    nextButtonCaption: 'common.button.next',
    backButtonCaption: 'common.button.back'
  }
});
