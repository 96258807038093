import React from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@material-ui/core';

import classNames from 'classnames';
import { useStyles } from './measurement-card.styles';
import { useTranslate } from 'react-admin';
import { MeasurementDetailTypeEnum, TileStat } from '../index';
import { DateFieldWithIcon } from '../../../common/DateFieldWithIcon';
import { formatMeasurement } from 'services/utils';

interface MeasurementCardProps extends TileStat {
  readonly label: string;
  readonly measurementType: MeasurementDetailTypeEnum;
}

export function MeasurementCard({ label, value, examDate, status, measurementType }: MeasurementCardProps) {
  const classes = useStyles();
  const translate = useTranslate();

  const tileClasses = classNames([
    classes.measurementValue,
    status === 'RED' ? classes.red : status === 'GREEN' ? classes.green : ''
  ]);

  if (value === null) {
    return null;
  }

  return (
    <Grid item component={Card} xs className={classes.container}>
      <CardContent className={classes.measurementContent}>
        <Typography variant="h6" component="p" align="center" className={classes.label}>
          {translate(label)}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="h3" component="p" className={tileClasses}>
            {formatMeasurement(value, measurementType)}
          </Typography>
        </Box>
        <DateFieldWithIcon date={examDate} />
      </CardContent>
    </Grid>
  );
}
