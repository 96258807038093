import { Box, Grid, Card } from '@material-ui/core';

import { CategoryCard } from '../category-card/category-card';
import { ExceedCard } from '../exceed-card/exceed-card';
import React, { useCallback, useMemo, useState } from 'react';
import { List } from 'react-admin';
import { CategoryField } from '../category-field/category-field';
import { DeviceField } from '../device-field/device-field';
import { LinkField } from '../link-field/link-field';
import { LocationField } from '../location-field/location-field';
import { PatientStatusField } from '../patient-status-field/patient-status-field';
import { constProvider } from 'providers';
import { DashboardDoctorFilters } from './dashboard-doctor-filters/';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardDoctorActions } from './dashboard-doctor-actions';
import { CustomDatagrid } from './custom-datagrid/custom-datagrid';
import { PatientCategoryEnum } from 'models/models';
import { MeasurmentDetailsField } from './measurment-details-field/measurment-details-field';
import { MeasurementDetailTypeEnum } from '../../measurement-details';

const useStyles = makeStyles({
  gridContainer: {
    margin: '0'
  },
  container: {
    marginTop: '50px'
  }
});

const categoryFilter = (value: any) => {
  const categories = Object.keys(constProvider.PATIENT_COLOR_TO_CATEGORY);
  return categories[value] as PatientCategoryEnum;
};

export function DashboardDoctor() {
  const [showFilters, setShowFilters] = useState(false);
  const rowSelectable = useCallback(() => false, []);

  const [category, setCategory] = useState<PatientCategoryEnum>(categoryFilter(0));

  function handleChange(newValue: any) {
    const category = categoryFilter(newValue);
    setCategory(category);
  }

  const toggleFilters = useCallback(() => setShowFilters(!showFilters), [showFilters]);

  const listProps = useMemo(
    () => ({
      basePath: '/',
      resource: constProvider.RESOURCES.DOCTOR_PATIENT_MEASUREMENT.URI,
      hasCreate: false,
      hasEdit: false,
      hasList: false,
      hasShow: false,
      bulkActionButtons: false,
      pagination: null,
      filters: showFilters ? <DashboardDoctorFilters /> : null,
      sort: { field: 'firstName', order: 'ASC' },
      actions: <DashboardDoctorActions toggleFilters={toggleFilters} />,
      exporter: false,
      filter: category === 'ALL' ? {} : { patientCategory: category },
      perPage: 50
    }),
    [showFilters, category, toggleFilters]
  );

  const classes = useStyles();

  const handleClickTemperature = useCallback(
    (patientId: string) => `/measurement/${patientId}/${MeasurementDetailTypeEnum.TEMPERATURE}`,
    []
  );

  const handleClickPulse = useCallback(
    (patientId: string) => `/measurement/${patientId}/${MeasurementDetailTypeEnum.PULSE}`,
    []
  );

  const handleClickSaturation = useCallback(
    (patientId: string) => `/measurement/${patientId}/${MeasurementDetailTypeEnum.SATURATION}`,
    []
  );

  return (
    <Box my={3}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <CategoryCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <ExceedCard />
        </Grid>
        <Grid item xs={12} component={Card} className={classes.container}>
          <List {...listProps}>
            <CustomDatagrid isRowSelectable={rowSelectable} category={handleChange}>
              <LinkField source="firstName" />
              <LinkField source="lastName" />
              <DeviceField source="deviceSerialNumber" />
              <MeasurmentDetailsField
                source="temperatureMeasurement"
                type={'temperature'}
                getLinkTarget={handleClickTemperature}
              />
              <MeasurmentDetailsField
                source="saturationMeasurement"
                type={'saturation'}
                getLinkTarget={handleClickSaturation}
              />
              <MeasurmentDetailsField source="pulseMeasurement" type={'pulse'} getLinkTarget={handleClickPulse} />
              <CategoryField source="patientCategory" />
              <LocationField source="patientLockdownStatus" />
              <PatientStatusField source="patientStatus" />
            </CustomDatagrid>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}
