import React, { useCallback } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import {
  SaveButton,
  FunctionField,
  TextInput,
  SelectInput,
  useTranslate,
  useRedirect,
  sanitizeEmptyValues,
  useNotify,
  useDataProvider,
  required,
  ReferenceInput
} from 'react-admin';
import { Box, Toolbar, Typography, Button, Divider, Grid } from '@material-ui/core';
import { constProvider } from 'providers';
import { parseFormErrors } from 'services/utils';
import { validatePhoneNumber } from 'services/validators';
import { DeviceFormValues } from './DeviceForm.models';
import { useStyles } from './DeviceForm.styles';
import { deviceConsts } from '../../Device.const';

type DeviceFormProps = any;

export function DeviceForm(props: DeviceFormProps) {
  const { record, basePath, version } = props;
  const notify = useNotify();
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const getData = useCallback(
    (values: DeviceFormValues) => {
      const payload = {
        simPhoneNumber: values.simPhoneNumber,
        simNumber: values.simNumber,
        deviceType: values.deviceType,
        medicalCenterId: values.medicalCenterId
      };

      return sanitizeEmptyValues(record, payload);
    },
    [record]
  );

  const submit = useCallback(
    async (values: DeviceFormValues) => {
      try {
        const response = await dataProvider.update(constProvider.RESOURCES.DEVICE.URI, {
          id: values.id,
          data: getData(values)
        });
        notify('ra.notification.updated', 'info', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          smart_count: 1
        });
        redirectTo('edit', basePath, response.data.id);
      } catch (error) {
        return parseFormErrors(error, translate);
      }
    },
    [dataProvider, basePath, getData, notify, redirectTo, translate]
  );

  const onCancelClick = useCallback(() => {
    redirectTo(`/${constProvider.RESOURCES.DEVICE.URI}`);
  }, [redirectTo]);

  const renderSerialNumber = useCallback(() => {
    return (
      <Typography variant="h6" component="p">
        {translate(deviceConsts.FIELDS.SERIAL_NUMBER)}: {record.serialNumber}
      </Typography>
    );
  }, [translate, record]);

  const renderStatus = useCallback(() => {
    return (
      <Typography className={classes.status}>
        {translate(deviceConsts.FIELDS.AVAILABLE)}:{' '}
        <span className={classes.statusValue}>
          {record.available
            ? translate(deviceConsts.AVAILABILITY.AVAILABLE)
            : translate(deviceConsts.AVAILABILITY.NOT_AVAILABLE)}
        </span>
      </Typography>
    );
  }, [translate, record, classes]);

  const phoneNumberValidator = useCallback(
    (value) => validatePhoneNumber(value, translate(deviceConsts.VALIDATION.PHONE_NUMBER)),
    [translate]
  );

  const renderForm = (formProps: FormRenderProps<DeviceFormValues>) => {
    return (
      <form>
        <Box className={classes.container}>
          <FunctionField label="" render={renderSerialNumber} />
          <FunctionField label="" render={renderStatus} />
          <Divider className={classes.divider} />
          <Typography className={classes.sectionHeader}>{translate(deviceConsts.EDIT_SUBTITLE)}:</Typography>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6}>
                <TextInput resource="device" source="simPhoneNumber" validate={phoneNumberValidator} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextInput resource="device" source="simNumber" validate={required()} fullWidth />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6}>
                <ReferenceInput
                  resource="device"
                  reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
                  source="medicalCenterId"
                  validate={required()}
                  fullWidth
                >
                  <SelectInput fullWidth />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6}>
                <ReferenceInput
                  resource="device"
                  reference={constProvider.RESOURCES.DEVICE_TYPES.URI}
                  source="deviceType"
                  validate={required()}
                  disabled
                  fullWidth
                >
                  <SelectInput fullWidth />
                </ReferenceInput>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button color="primary" className={classes.cancelButton} onClick={onCancelClick}>
              {translate(deviceConsts.BUTTONS.CANCEL_BUTTON)}
            </Button>
            <SaveButton saving={formProps.submitting} handleSubmitWithRedirect={formProps.handleSubmit} />
          </Box>
        </Toolbar>
      </form>
    );
  };

  return (
    <Form
      initialValues={record}
      onSubmit={submit}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      render={renderForm}
    />
  );
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
};
