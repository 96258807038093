import React, { useEffect, useState } from 'react';
import { useDataProvider, UserMenu } from 'react-admin';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { constProvider } from 'providers';

import { useStyles } from './CustomUserMenu.styles';

type UserInfo = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  medicalCenterId: string;
};

const CustomUserMenu = (props: any) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [initials, setInitials] = useState<string>('');
  const dataProvider = useDataProvider();
  const classes = useStyles();

  useEffect(() => {
    dataProvider
      .getCustomUrl(constProvider.RESOURCES.USER_BASIC.URI)
      .then(({ data }: { data: UserInfo }) => {
        const firstName = data.firstName || '';
        const lastName = data.lastName || '';
        setFirstName(firstName);
        setLastName(lastName);
        setInitials(`${firstName.substring(0, 1)}${lastName.substring(0, 1)}`);
      })
      .catch(() => {
        setFirstName('');
        setLastName('');
        setInitials('');
      });
  }, [dataProvider]);

  return (
    <div className={classes.userMenu}>
      <div className={classes.namesContainer}>
        <p className={classes.name}>
          {firstName} {lastName}
        </p>
      </div>
      <div className={classes.circle}>
        <span className={classes.initials}>{initials}</span>
      </div>
      <UserMenu {...props} icon={<ArrowDropDownIcon />} className={classes.originalUserMenu} />
    </div>
  );
};

export default CustomUserMenu;
