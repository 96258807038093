import { FunctionField, useTranslate } from 'react-admin';
import React, { useCallback } from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Favorite as FavoriteIcon, Opacity as OpacityIcon, WbIridescent as WbIridescentIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
// import { get } from 'lodash';
import { constProvider } from 'providers';
import { MeasurementDto, MeasurementTypeEnum } from '../../../models/models';

type MeasurementTypeFieldProps = {
  source: string;
} & any;

export const useStyles = makeStyles(() => ({
  icon: {
    color: 'black'
  }
}));

export function MeasurementTypeField({ source, ...restProps }: MeasurementTypeFieldProps) {
  const translate = useTranslate();
  const { icon } = useStyles();

  const renderMeasurementTypeField = useCallback(
    (props: MeasurementDto) => {
      const { measurementType } = props;

      const getName = (measurementType: MeasurementTypeEnum) => {
        return translate(`common.measurementType.${measurementType ? measurementType.toLowerCase() : ''}`);
      };

      const getIcon = (measurementType: MeasurementTypeEnum) => {
        switch (measurementType) {
          case constProvider.MEASUREMENT_TYPE.SATURATION.toLocaleUpperCase():
            return <OpacityIcon fontSize="small" color="inherit" className={icon} />;
          case constProvider.MEASUREMENT_TYPE.PULSE.toLocaleUpperCase():
            return <FavoriteIcon fontSize="small" color="inherit" className={icon} />;
          case constProvider.MEASUREMENT_TYPE.TEMPERATURE.toLocaleUpperCase():
            return <WbIridescentIcon fontSize="small" color="inherit" className={icon} />;
          default:
            return undefined;
        }
      };

      const getType = (measurementType: MeasurementTypeEnum) => {
        const name = getName(measurementType);
        const icon = getIcon(measurementType);
        return (
          <Tooltip title={name} placement="right">
            <IconButton aria-label={name}>{icon}</IconButton>
          </Tooltip>
        );
      };

      return (
        <Link to={`${constProvider.RESOURCES.MEASUREMENT.URI}/${measurementType}`}>{getType(measurementType)}</Link>
      );
    },
    [translate, icon]
  );

  return <FunctionField source={source} render={renderMeasurementTypeField} {...restProps} />;
}
