import { PageContentWithTitle } from 'components/common';
import React, { useEffect } from 'react';
import { cond, constant, includes, matches } from 'lodash';
import { measurementDetailConsts } from './measurement-detail.const';
import { Grid } from '@material-ui/core';
import { Loading, useRedirect } from 'react-admin';
import { Chart } from './chart/chart';
import { ListCard } from './list-card/list-card';
import { BasicInfoCard } from './basic-info-card/basic-info-card';
import { useParams } from 'react-router-dom';
import { usePermissionsMap } from '../../../hooks/common';
import { NotAuthorized } from '../../common/NotAuthorized/NotAuthorized';
import { MeasurementTiles } from './measurement-card';

export enum MeasurementDetailTypeEnum {
  PULSE = 'PULSE',
  TEMPERATURE = 'TEMPERATURE',
  SATURATION = 'SATURATION'
}

export type MeasurementType = 'PULSE' | 'TEMPERATURE' | 'SATURATION';

export interface TileStat {
  readonly examDate: string;
  readonly statisticType: 'MINIMUM' | 'MAXIMUM' | 'AVERAGE';
  readonly status: 'RED' | 'GREEN';
  readonly value: number;
}

export function MeasurementDetailEdit() {
  const permissions = usePermissionsMap();
  const redirect = useRedirect();
  const { patientId, measurementType } = useParams();

  useEffect(() => {
    if (
      !includes(
        [MeasurementDetailTypeEnum.PULSE, MeasurementDetailTypeEnum.SATURATION, MeasurementDetailTypeEnum.TEMPERATURE],
        measurementType
      )
    ) {
      redirect('/');
    }
  }, [measurementType, redirect]);

  if (permissions.size === 0) {
    return <Loading />;
  }

  if (!permissions.any('SECTION_RESULTS_READ_ONLY_PROPERTIES')) {
    return <NotAuthorized />;
  }

  return (
    <PageContentWithTitle title={getPageSettings(measurementType).title}>
      <Grid container spacing={3}>
        <Grid container alignItems="stretch">
          <BasicInfoCard patientId={patientId} />
          <MeasurementTiles patientId={patientId} measurementType={measurementType as MeasurementDetailTypeEnum} />
        </Grid>
        <Grid item xs={12}>
          <Chart patientId={patientId} measurementType={measurementType as MeasurementDetailTypeEnum} />
        </Grid>
        <Grid item xs={12}>
          <ListCard patientId={patientId} measurementType={measurementType} />
        </Grid>
      </Grid>
    </PageContentWithTitle>
  );
}

function getPageSettings(measurementType: MeasurementType) {
  const { PAGE_TITLE } = measurementDetailConsts;

  return cond([
    [matches(MeasurementDetailTypeEnum.PULSE), constant({ title: PAGE_TITLE.PULSE })],
    [matches(MeasurementDetailTypeEnum.SATURATION), constant({ title: PAGE_TITLE.SATURATION })],
    [matches(MeasurementDetailTypeEnum.TEMPERATURE), constant({ title: PAGE_TITLE.TEMPERATURE })]
  ])(measurementType);
}

export interface Measurement {
  readonly creationDate: string;
  readonly description: string | null;
  readonly descriptionChangeDate: string | null;
  readonly descriptionDate: string | null;
  readonly id: string;
  readonly isImportant: boolean;
  readonly measurementType: MeasurementType;
  readonly status: string;
  readonly userId: string;
  readonly value: number;
}
