import 'chartjs-plugin-annotation';

import { Box, Card, Grid, Typography } from '@material-ui/core';
import { CategorizedMeasurementDto, MeasurementDetailsDto, MeasurementTypeEnum } from 'models/models';
import React, { useEffect, useRef, useState } from 'react';
import { getAnnotations, getLineColor } from './chart-utils';

import Chartjs from 'chart.js';
import { constProvider } from 'providers';
import moment from 'moment';
import { patientDashboardConsts } from '../patient-dashboard.const';
import { useStyles } from './chart.styles';
import { useTranslate } from 'react-admin';
import { get } from 'lodash';

type MeasurementChartProps = {
  data: MeasurementDetailsDto;
  legend: string;
  chartType: MeasurementTypeEnum;
  thresholds?: Map<string, number>;
};

export function Chart({ legend, chartType, data, thresholds }: MeasurementChartProps) {
  const translate = useTranslate();
  const chartContainer = useRef<HTMLCanvasElement>(null);
  // eslint-disable-next-line
  const [chartInstance, setChartInstance] = useState<Chart>();
  const styleProps = { color: getLineColor(chartType) };
  const classes = useStyles(styleProps);

  useEffect(() => {
    const chartConfig = {
      type: 'line',
      data: {
        datasets: [
          {
            data: mappedChartData(get(data, 'stats', [])),
            borderWidth: 2,
            fill: false,
            lineTension: 0,
            borderColor: getLineColor(chartType),
            datalabels: {
              labels: {
                title: null
              }
            }
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            title: () => {
              return '';
            },
            label: (tooltipItem: any, item: any) => {
              const value = item.datasets[0]['data'][tooltipItem['index']]['y'] || '';
              const date = item.datasets[0]['data'][tooltipItem['index']]['t'] || '';
              return `${value}${chartType === 'TEMPERATURE' ? ' °C ' : ' '}${dateFormat(date)}`;
            }
          },
          backgroundColor: '#424242',
          bodyFontColor: '#fff',
          displayColors: false,
          cornerRadius: 3
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                borderDash: [4, 6],
                color: '#e9ebf1'
              },
              id: 'y-axis-0',
              ticks: {
                callback: function (value: number) {
                  return `${value}${chartType === 'TEMPERATURE' ? '°C' : ''}`;
                }
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                borderDash: [4, 6],
                color: '#e9ebf1'
              },
              id: 'x-axis-0',
              type: 'time'
            }
          ]
        },
        annotation: {
          annotations: [...getAnnotations(thresholds)]
        }
      }
    };

    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, data, chartType, thresholds]);

  function dateFormat(date: any) {
    return moment(date).format(constProvider.DATE_TIME_FORMAT);
  }

  if (!data || !thresholds) return null;

  return (
    <Card>
      <Box className={classes.legend}>
        <Typography variant="body1" className={classes.mediumText}>
          {legend}
        </Typography>
        <Grid container className={classes.items}>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body2">
                {translate(
                  chartType === 'SATURATION'
                    ? patientDashboardConsts.CHART.LOWEST
                    : patientDashboardConsts.CHART.HIGHEST
                )}
              </Typography>
              <Typography className={classes.highest}>
                {chartType === 'SATURATION' ? data.minValue : data.maxValue} {chartType === 'TEMPERATURE' && '°C'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body2">
                {translate(
                  chartType === 'SATURATION'
                    ? patientDashboardConsts.CHART.HIGHEST
                    : patientDashboardConsts.CHART.LOWEST
                )}
              </Typography>
              <Typography className={classes.lowest}>
                {chartType === 'SATURATION' ? data.maxValue : data.minValue} {chartType === 'TEMPERATURE' && '°C'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <span className={classes.line}></span>
              <Typography className={classes.title}>{legend}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <canvas ref={chartContainer} />
    </Card>
  );
}

function mappedChartData(stats: CategorizedMeasurementDto[]) {
  return stats.map(({ examDate, value }) => {
    return {
      t: moment(examDate).toDate(),
      y: Number(value)
    };
  });
}
