import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { Edit, useTranslate } from 'react-admin';
import { Tabs, Tab } from '@material-ui/core';
import * as Types from 'AppTypes';
import { getMeasuringDevice } from 'features/resources/selectors';
import { PageContentWithTitle, TabPanel, EditContentWrapper } from 'components/common';
import { measuringDeviceConsts } from '../measuring-device.const';
import { MeasuringDeviceForm } from './measuring-device-form/measuring-device-form';

export function MeasuringDeviceEdit(props: any) {
  const translate = useTranslate();
  const measuringDevice = useSelector((state: Types.RootState) => getMeasuringDevice(state, props.id));
  const [selectedTabValue, setSelectedTabValue] = useState<number>(0);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setSelectedTabValue(newValue);
    },
    [setSelectedTabValue]
  );

  const titleInterpolation = useMemo(() => ({ name: measuringDevice?.serialNumber }), [measuringDevice]);

  const renderContent = (editProps: any) => {
    return (
      <>
        <Tabs
          value={selectedTabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="Measuring device tabs"
        >
          <Tab label={translate(measuringDeviceConsts.TABS.PARAMETERS_TAB)} />
          <Tab label={translate(measuringDeviceConsts.TABS.HISTORY_TAB)} disabled />
          <Tab label={translate(measuringDeviceConsts.TABS.MEASUREMENT_TAB)} disabled />
        </Tabs>
        <TabPanel index={0} value={selectedTabValue}>
          <MeasuringDeviceForm {...editProps} />
        </TabPanel>
      </>
    );
  };

  return (
    <PageContentWithTitle
      title={!isNil(measuringDevice) ? measuringDeviceConsts.EDIT_HEADER : undefined}
      titleInterpolation={titleInterpolation}
    >
      <Edit {...props} title={translate(measuringDeviceConsts.EDIT_TITLE)}>
        <EditContentWrapper>{(editProps: any) => renderContent(editProps)}</EditContentWrapper>
      </Edit>
    </PageContentWithTitle>
  );
}
