import { FunctionField } from 'react-admin';
import React, { useCallback } from 'react';
import { MeasurementDetailTypeEnum } from 'components/resources/measurement-details';
import { formatMeasurement, getMeasurementStyleForCategory } from 'services/utils';

type MeasurementFieldProps = {
  source: string;
  label?: string;
  statusSource?: string;
  measurementType: MeasurementDetailTypeEnum;
  className?: string;
};

export function MeasurementField({
  source,
  statusSource = 'status',
  measurementType,
  className = '',
  ...restProps
}: MeasurementFieldProps) {
  const renderBooleanField = useCallback(
    (record: any) => (
      <span className={className} style={getMeasurementStyleForCategory(record[statusSource])}>
        {formatMeasurement(record[source], measurementType)}
      </span>
    ),
    [statusSource, source, measurementType, className]
  );

  return <FunctionField source={source} render={renderBooleanField} {...restProps} />;
}
