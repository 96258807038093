import { cond, constant, matches } from 'lodash';

import { colors } from 'style/colors';

interface Annotation {
  type: 'line';
  id: string;
  mode: 'horizontal';
  scaleID: string;
  value: number;
  borderColor: string;
  borderWidth: number;
}

export function getAnnotations(tresholds: Map<string, number> | undefined): Annotation[] {
  return !tresholds
    ? []
    : ([...tresholds.keys()]
        .map((color, i) => {
          const lineColor = getTresholdLineColor(color);
          return null === lineColor
            ? null
            : {
                type: 'line' as 'line',
                id: `hline${i}`,
                mode: 'horizontal' as 'horizontal',
                scaleID: 'y-axis-0',
                value: tresholds.get(color),
                borderColor: lineColor,
                borderWidth: 1
              };
        })
        .filter((value) => value !== null) as Annotation[]);
}

const getTresholdLineColor = cond([
  [matches('RED'), constant(colors.red)],
  [matches('YELLOW'), constant(colors.yellow)],
  [matches('GREEN'), constant(colors.listGreen)],
  [matches('BLUE'), constant(colors.primaryBlue)],
  [() => true, constant(null)]
]);

export const getLineColor = cond([
  [matches('TEMPERATURE'), constant(colors.green)],
  [matches('SATURATION'), constant(colors.primaryBlue)],
  [matches('PULSE'), constant(colors.primaryBlueDark)]
]);
